import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
import "./devexpress-grid-style.css"
import { DATE_COL_DEF, removeUndefinedProps } from "@material-ui/data-grid";
import { privateEncrypt } from "exceljs/dist/exceljs";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  containerText:{
    maxWidth: 800,
            margin: 0,
            background: "#fff",
            padding: 20,
            borderRadius: 8,
            //boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  }
  ,
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  drawer: {
    width: 300,
    maxWidth: 300,
  },
});

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class privacy extends Component {
  state = {
    value: 0,
    openDrawer: false,
    idHabitacion: 0,
    nombre: null,
    numero: 0,
    usuarioCreacion: null,
    usuarioModificacion: null,
    fechaCreacion: null,
    fechaModificacion: null,
    activo: null,
    eliminado: null,
    listaHabitaciones: [],
    buscando: false,
    expanded: false,

    // listaUsuarios: [],
    // usuarioSolicitanteActual: null,
    // usuarioAutorizaActual: null,
    // usuarioHotelAutorizaActual: null,
    // idUsuarioSolicitante: 0,
    // idUsuarioAutoriza: 0,
    // idUsuarioHotelAutoriza: 0,
    // checkout: null,
    eliminada: false,

    //layout
    openToolbar: false,
    reservaId: null,
    fechaDisponibilidad: null,
    listaDisponibilidad: [],
    openModalDisponibilidad: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Mantenimiento.Habitaciones";
    // await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const session = await isSignedIn();

    let fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 30);

    if (session.response) {
      this.setState(
        {
          fechaInicio: convertToYearMonthDay(fechaInicio),
          fechaFin: convertToYearMonthDay(new Date()),
        },
        async () => {
          await this.cargarListaHabitaciones();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  cerrarModalHabitaciones = () => {
    console.log("CerrarModalHabitaciones");
    this.setState({
      openModalEtiquetas: false,
      idHabitacion: null,
      nombre: null,
      numero: null,
    });
    this.cargarListaHabitaciones();
  };

  cargarDatosEdicion = async (habitacionId) => {
    this.setState({ openModalEtiquetas: true, idHabitacion: habitacionId });
  };

  inhabilitarHabitacion = async (habitacionId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/desactivarHabitacion/${habitacionId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  remover = async (reservaId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/anularReserva/${reservaId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  async cargarListaHabitaciones() {
    console.log("que ondas");
    const request = await API.fetchGetRequest(
      `api/admonHotel/habitacionesList?todas=true`
    );
    if (Array.isArray(request.data.data)) {
      let listaHabit = request.data.data;
      // listaHabit.push({
      //   id: 0,
      //   Nombre: "Todas",
      //   Descripcion: "",
      //   Activo: true,
      // });

      this.setState({
        listaHabitaciones: listaHabit,
      });
    }
  }

  limpiar = () => {
    this.setState({
      usuarioSolicitanteActual: null,
      usuarioAutorizaActual: null,
      usuarioHotelAutorizaActual: null,
      idUsuarioSolicitante: 0,
      idUsuarioAutoriza: 0,
      idUSuarioHotelAutoriza: 0,

      buscando: false,
      expanded: false,
      //layout
      openToolbar: false,
    });
  };

  handleChange = (name) => (event) => {
    if (
      name == "pendientes" ||
      name == "terminados" ||
      name == "slaVencido" ||
      name == "slaVigente"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleDrawerOpen = async () => {
    this.setState({ openToolbar: true });
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  redireccionar = (codigoGestion) => {
    this.props.history.push(`/gestion/${codigoGestion}`);
    //window.location.href="/gestion/" + codigoGestion;
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout titulo={"Política de privacidad"} maxWidth="xl">
        <div class="containerText">
          <h1>Privacy Policy</h1>
          <p>
            <strong>Effective Date:</strong> 2025-01-19
          </p>
          <p>
            Ingenio La Unión (“we,” “our,” or “us”) is committed to protecting
            the privacy of its employees and ensuring that their personal data
            is handled securely and responsibly. This Privacy Policy explains
            how we collect, use, and protect the data gathered through our
            health monitoring application, utilizing Huawei smart bands and
            services.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We collect the following health-related data from employees
            participating in the monitoring program:
          </p>
          <ul>
            <li>
              <strong>Heart Rate</strong>
            </li>
            <li>
              <strong>Oxygen Saturation (SpO2)</strong>
            </li>
          </ul>
          <p>
            These metrics are collected through Huawei smart bands and
            transmitted securely to Huawei Health servers before being made
            accessible to our internal monitoring system.
          </p>

          <h2>2. How We Use Your Data</h2>
          <p>
            The data collected is used exclusively for the following purposes:
          </p>
          <ul>
            <li>
              Monitoring employees’ health in real time to ensure their safety
              while performing physically demanding tasks.
            </li>
            <li>
              Identifying potential health risks and enabling prompt medical
              intervention.
            </li>
            <li>
              Generating aggregated insights to improve workplace safety and
              well-being.
            </li>
          </ul>

          <h2>3. Data Sharing</h2>
          <p>
            We do not share your personal data with any third-party companies,
            institutions, or organizations. Your data is accessed only by
            authorized personnel at Ingenio La Unión, such as supervisors and
            medical staff, and is used solely for the purposes outlined in this
            policy.
          </p>

          <h2>4. Role of Huawei</h2>
          <p>
            As part of this project, Huawei acts as a technology provider by
            offering its smart bands and Huawei Health services to securely
            collect and store your health data. Ingenio La Unión accesses this
            data via Huawei's developer platform in compliance with Huawei's
            security and privacy standards.
          </p>
          <p>
            Huawei does not process or share your health data with any other
            parties and adheres to strict global data protection regulations.
            For more details, please refer to Huawei's Privacy Policy at{" "}
            <a href="[Huawei Privacy Policy Link]" target="_blank">
              Huawei Privacy Policy
            </a>
            .
          </p>

          <h2>5. Data Security</h2>
          <p>
            We implement robust security measures to protect your data,
            including:
          </p>
          <ul>
            <li>
              Encryption during data transmission between Huawei smart bands,
              Huawei servers, and our internal system.
            </li>
            <li>
              Restricted access to data, limited to authorized personnel only.
            </li>
            <li>
              Regular audits to ensure compliance with privacy and security
              standards.
            </li>
          </ul>

          <h2>6. Your Rights</h2>
          <p>As a participant, you have the right to:</p>
          <ul>
            <li>
              Access your personal health data collected through this program.
            </li>
            <li>
              Request corrections to your data if inaccuracies are identified.
            </li>
            <li>
              Withdraw from the monitoring program at any time, in which case we
              will cease collecting your data.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact{" "}
            <strong>soporte@launion.com.gt</strong>.
          </p>

          <h2>7. Changes to This Policy</h2>
          <p>
            We reserve the right to update this Privacy Policy as necessary. Any
            changes will be communicated to participants through official
            channels before they take effect.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have questions or concerns about this Privacy Policy, please
            contact us at:
          </p>
          <ul>
            <li>
              <strong>Email:</strong> soporte@launion.com.gt
            </li>
            <li>
              <strong>Phone:</strong>  (502) 7879-1697 Ext. 1515
            </li>
          </ul>
          <p>
            By participating in this monitoring program, you acknowledge that
            you have read and understood this Privacy Policy and consent to the
            collection and use of your data as described herein.
          </p>
        </div>
      </Layout>
    );
  }
}
const privacyScreen = withStyles(styles)(privacy);
export default withRouter(privacyScreen);
