import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
import "./devexpress-grid-style.css"
import { DATE_COL_DEF, removeUndefinedProps } from "@material-ui/data-grid";
import { privateEncrypt } from "exceljs/dist/exceljs";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  containerText:{
    maxWidth: 800,
            margin: 0,
            background: "#fff",
            padding: 20,
            borderRadius: 8,
            //boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  }
  ,
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  drawer: {
    width: 300,
    maxWidth: 300,
  },
});

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class agreement extends Component {
  state = {
    value: 0,
    openDrawer: false,
    idHabitacion: 0,
    nombre: null,
    numero: 0,
    usuarioCreacion: null,
    usuarioModificacion: null,
    fechaCreacion: null,
    fechaModificacion: null,
    activo: null,
    eliminado: null,
    listaHabitaciones: [],
    buscando: false,
    expanded: false,

    // listaUsuarios: [],
    // usuarioSolicitanteActual: null,
    // usuarioAutorizaActual: null,
    // usuarioHotelAutorizaActual: null,
    // idUsuarioSolicitante: 0,
    // idUsuarioAutoriza: 0,
    // idUsuarioHotelAutoriza: 0,
    // checkout: null,
    eliminada: false,

    //layout
    openToolbar: false,
    reservaId: null,
    fechaDisponibilidad: null,
    listaDisponibilidad: [],
    openModalDisponibilidad: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Mantenimiento.Habitaciones";
    // await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const session = await isSignedIn();

    let fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 30);

    if (session.response) {
      this.setState(
        {
          fechaInicio: convertToYearMonthDay(fechaInicio),
          fechaFin: convertToYearMonthDay(new Date()),
        },
        async () => {
          await this.cargarListaHabitaciones();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  cerrarModalHabitaciones = () => {
    console.log("CerrarModalHabitaciones");
    this.setState({
      openModalEtiquetas: false,
      idHabitacion: null,
      nombre: null,
      numero: null,
    });
    this.cargarListaHabitaciones();
  };

  cargarDatosEdicion = async (habitacionId) => {
    this.setState({ openModalEtiquetas: true, idHabitacion: habitacionId });
  };

  inhabilitarHabitacion = async (habitacionId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/desactivarHabitacion/${habitacionId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  remover = async (reservaId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/anularReserva/${reservaId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  async cargarListaHabitaciones() {
    console.log("que ondas");
    const request = await API.fetchGetRequest(
      `api/admonHotel/habitacionesList?todas=true`
    );
    if (Array.isArray(request.data.data)) {
      let listaHabit = request.data.data;
      // listaHabit.push({
      //   id: 0,
      //   Nombre: "Todas",
      //   Descripcion: "",
      //   Activo: true,
      // });

      this.setState({
        listaHabitaciones: listaHabit,
      });
    }
  }

  limpiar = () => {
    this.setState({
      usuarioSolicitanteActual: null,
      usuarioAutorizaActual: null,
      usuarioHotelAutorizaActual: null,
      idUsuarioSolicitante: 0,
      idUsuarioAutoriza: 0,
      idUSuarioHotelAutoriza: 0,

      buscando: false,
      expanded: false,
      //layout
      openToolbar: false,
    });
  };

  handleChange = (name) => (event) => {
    if (
      name == "pendientes" ||
      name == "terminados" ||
      name == "slaVencido" ||
      name == "slaVigente"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleDrawerOpen = async () => {
    this.setState({ openToolbar: true });
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  redireccionar = (codigoGestion) => {
    this.props.history.push(`/gestion/${codigoGestion}`);
    //window.location.href="/gestion/" + codigoGestion;
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout titulo={"Agreement"} maxWidth="xl">
       <div class="containerText">
        <h1>Employee Agreement for Health Monitoring Program</h1>
        <p><strong>Effective Date:</strong>2025-01-20</p>
        <p><strong>This Agreement</strong> is made between <strong>Ingenio La Unión</strong> (“the Company”) and <strong>[Employee Name]</strong> (“the Employee”), who agrees to participate in the Health Monitoring Program.</p>
        <p>By signing this Agreement, the Employee acknowledges and agrees to the following terms and conditions:</p>

        <h2>1. Purpose of the Program</h2>
        <p>The purpose of the Health Monitoring Program is to enhance workplace safety and ensure the well-being of employees performing physically demanding tasks. Using Huawei smart bands, the program will monitor health metrics such as heart rate and oxygen saturation to detect potential health risks and enable timely medical intervention.</p>

        <h2>2. Data Collected</h2>
        <p>The Employee agrees to the collection of the following health-related data through the Huawei smart bands:</p>
        <ul>
            <li>Heart rate</li>
            <li>Oxygen saturation (SpO2)</li>
        </ul>
        <p>These data will be transmitted securely to Huawei Health servers and accessed by authorized personnel of the Company through Huawei's developer platform.</p>

        <h2>3. Data Usage</h2>
        <p>The Employee understands that the data collected will be used exclusively for the following purposes:</p>
        <ul>
            <li>Monitoring real-time health conditions to identify potential risks.</li>
            <li>Providing timely medical assistance when necessary.</li>
            <li>Generating insights to improve workplace safety and employee health.</li>
        </ul>

        <h2>4. Data Privacy and Security</h2>
        <ul>
            <li>The Employee’s health data will not be shared with any third-party companies, institutions, or organizations outside the Company.</li>
            <li>Only authorized personnel, such as supervisors and medical staff, will have access to the data.</li>
            <li>Huawei, as a technology provider, ensures secure data collection and storage and adheres to strict global data protection regulations.</li>
        </ul>

        <h2>5. Employee Rights</h2>
        <p>The Employee retains the following rights regarding their health data:</p>
        <ul>
            <li>To access their personal health data collected through the program.</li>
            <li>To request corrections to their data if inaccuracies are identified.</li>
            <li>To withdraw from the program at any time, in which case the Company will cease collecting their data.</li>
        </ul>
        <p>Requests regarding these rights can be submitted by contacting <strong>[Insert Contact Information]</strong>.</p>

        <h2>6. Responsibilities of the Employee</h2>
        <p>By signing this Agreement, the Employee agrees to:</p>
        <ul>
            <li>Wear the Huawei smart band during working hours as instructed.</li>
            <li>Report any issues or malfunctions of the device to the Company immediately.</li>
            <li>Use the device responsibly and avoid any intentional damage or misuse.</li>
        </ul>

        <h2>7. Responsibilities of the Company</h2>
        <p>The Company agrees to:</p>
        <ul>
            <li>Provide the Huawei smart band to the Employee at no cost.</li>
            <li>Ensure the secure handling and storage of the Employee’s health data.</li>
            <li>Notify the Employee of any updates or changes to the program or this Agreement.</li>
        </ul>

        <h2>8. Termination of the Agreement</h2>
        <p>This Agreement may be terminated by either party at any time. Upon termination, the Employee will return the Huawei smart band to the Company, and no further data will be collected.</p>

        <h2>9. Acknowledgment and Consent</h2>
        <p>By signing this Agreement, the Employee acknowledges that they have read and understood its terms and conditions. The Employee consents to the collection, use, and processing of their health data as described in this Agreement.</p>

        {/* <div class="signature-section">
            <p><strong>Ingenio La Unión Representative:</strong></p>
            <p>Name: <span class="signature-line"></span></p>
            <p>Position: <span class="signature-line"></span></p>
            <p>Signature: <span class="signature-line"></span></p>
            <p>Date: <span class="signature-line"></span></p>

            <p><strong>Employee:</strong></p>
            <p>Name: <span class="signature-line"></span></p>
            <p>Position: <span class="signature-line"></span></p>
            <p>Signature: <span class="signature-line"></span></p>
            <p>Date: <span class="signature-line"></span></p>
        </div> */}
    </div>
      </Layout>
    );
  }
}
const agreementScreen = withStyles(styles)(agreement);
export default withRouter(agreementScreen);
