import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckIcon from "@material-ui/icons/Check";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import IconButton from "@material-ui/core/IconButton";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { consultarPermiso } from "../../utils/helpers";
import InputAdornment from "@material-ui/core/InputAdornment";
import FunctionsIcon from "@material-ui/icons/Functions";

//Inputs Formulario

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";

//Appbar
import { fade, makeStyles } from "@material-ui/core/styles";

//Own components
import Layout from "../../layout/containers/Layout";
import DataGrid from "../../helpers/components/data-grid";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertYYYYMMDDToDate, convertirHHMM } from "../../utils/helpers";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import DatosEmpleado from "../../ficha-clinica/components/datos-empleado";
import ExpandableComponent from "../components/expandable-componente";
//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
//import classes from "*.module.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "50%",
    height: "200px",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  item: {
    paddingLeft: 3 * theme.spacing.unit,
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
  inputField: {
    textAlign: "start",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class CrearAviso extends Component {
  state = {
    codigoVacaciones: 280,
    codigoClinica: 419,
    codigoProblema: 419,
    dpiPaciente: 0,
    titulo: "",
    descripcion: "",
    campos: [],
    camposObligatoriosVacios: [],
    problema: null,
    codigoPlantilla: "",
    tiempoEsperaEstimadoSegundos: "",
    tiempoEsperaEstimado: "",
    problema: null,
    codigoPlantillaTareas: "",
    codigoEntidad: "",
    usuarioAfectado: "",
    codigoUsuarioActual: "",
    nombreUsuarioActual: "",
    usuarioActual: {},
    // usuarioActualInput:"",
    plantillaValores: [],
    plantilla: [],
    location: { lat: 0, lon: 0 },
    listaUsuarios: [],
    openAlert: false,
    procesando: false,
    response: false,
    cantidadArchivosAdjuntar: 0,
    numeroIteracionAdjunto: 0,
    creacionAvisoCompletada: false,
    cargaAdjuntosCompletada: false,
    nombreProblema: "",
    codigoLocal: 0, //CodigoLocal es un identificador temporal (en memoria) para los registros que se crean en tiempo de ejecución
    fechaVencimiento: null,
    fechasVacaciones: [],
    vacacionesPeriodos: [],
    distribucionVacaciones: [],
    diasMaximosVacaciones: 0,
    activarDatePicker: false,
    codigoPlantillaNotificaciones: null,
    listaPlantillasAlerta: [],
    dataEmpleado: {},
    encontrado: false,
    dataUsuario: {},
    existeUsuario: false,
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    if (
      this.props.match.params.dpi === null ||
      this.props.match.params.dpi === undefined
    ) {
      window.location = "/home";
    } else if (!isNaN(this.props.match.params.dpi)) {
      const session = await isSignedIn();
      if (session.response) {
        const requestCodGestionClinica = await API.fetchGetRequest(
          `api/fichaClinica/getCodigoProblemaClinica`
        );
        const verificarGestionClinica = requestCodGestionClinica.data.data;
        //Cargar lista subentidades
        this.setState(
          {
            codigoProblema: verificarGestionClinica, //TODO
            codigoUsuarioActual: session["codigoUsuario"],
            usuarioAfectado: parseInt(session["codigoUsuario"]),
            nombreUsuarioActual: session["NombreCompletoUsuario"],
            dpi: parseInt(this.props.match.params.dpi), //usuarioActual
          },
          async () => {
            //Valida si el rol del usuario tiene acceso a este recurso
            let permisos = await consultarPermiso(
              this.state.codigoProblema,
              "AITProblema"
            );
            if (!permisos.E) {
              toast.warn("No tienes permiso para ejecutar este elemento");
              setTimeout(() => {
                window.location.href = "/admongestiones";
              }, 3000);
            }

            //this.cargarListaPlantillaNotificaciones();

            await this.cargarDatosProblema();
            //await this.cargarCampos();
            await this.getPlantillaCampos();
            //this.cargarListaUsuarios();
            this.getLocation();
            await this.buscar();
            await this.buscarUsuario();

            console.log(this.state.usuarioActual);
          }
        );
      } else {
        window.location.href = "/login";
      }
    }
  }

  buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      this.setState({
        buscando: true,
        encontrado: false,
      });

      let uri = "api/fichaClinica/empleado/" + this.state.dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          this.state.dataEmpleado = request.data.data[0];
          this.state.encontrado = true;
          this.setState({
            titulo: `${this.state.titulo} - Paciente: ${request.data.data[0].NombreEmpleado}`,
          });
        } else {
          toast.error("No se encontró al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ buscando: false });
    }
  };

  buscarUsuario = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      this.setState({
        existeUsuario: false,
      });

      let uri = "api/desktop/usuario/dpi/" + this.state.dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log("DATOS");
        console.log(request);
        if (request.data.usuario) {
          this.state.dataUsuario = request.data.usuario;
          this.state.existeUsuario = true;
        } else {
          toast.info("Se creara el usuario en APP ILU");
          let requestNuevoUsuario;
          const data = {
            NombreCompletoUsuario: this.state.dataEmpleado.NombreEmpleado,
            Usuario: this.state.dataEmpleado.DPI,
            CorreoElectronico: "",
            AutenticacionWindows: false,
            UsuarioInserto: session["userName"],
          };

          requestNuevoUsuario = await API.post(`api/desktop/usuario`, data);
          if (requestNuevoUsuario.status !== 200) {
            toast.error("Hubo un error al crear al usuario. Contacte a TI");
          } else {
            let uri = "api/desktop/usuario/dpi/" + this.state.dataEmpleado.DPI;

            const request2 = await API.fetchGetRequest(uri);
            if (request2.statusCode === 200) {
              if (request2.data.usuario) {
                this.state.dataUsuario = request2.data.usuario;
                this.state.existeUsuario = true;

                toast.success("El usuario ha sido creado con exito");
              } else {
                toast.error("Hubo un error al crear al usuario. Contacte a TI");
              }
            } else {
              toast.error("Hubo un error al crear al usuario. Contacte a TI");
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  async cargarCampos() {
    try {
      //Loader
      this.setState({ cargando: true });
      const codigoPlantilla = this.state.codigoPlantilla;
      const request = await API.fetchGetRequest(
        `api/aviso/plantilla/${codigoPlantilla}`
      );
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState(
            {
              campos: request.data.data,
              plantilla: request.data.data,
            },
            () => {
              //Copia campos, con un atributo más, que se llama value
              const campos = this.state.campos;
              campos.forEach((item, index) => {
                let nombre = "campo" + item.CodigoCampo.toString();
                this.setState({
                  [nombre]:
                    item.ValorPorDefecto !== null ? item.ValorPorDefecto : "",
                });
              });
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }
  async cargarListaPlantillaNotificaciones() {
    const request = await API.fetchGetRequest(`api/plantillaalerta/listar`);
    if (Array.isArray(request.data.data)) {
      let lista = request.data.data;
      lista.push({ CodigoPlantillaAlerta: 0, Nombre: "Ninguna" });
      console.log("LIsta plantillas notify", lista);
      this.setState({
        listaPlantillasAlerta: lista,
      });
    }
  }

  getPlantillaCampos = async () => {
    const { problema, codigoPlantilla } = this.state;

    try {
      const uri = `api/aviso/plantilla/${codigoPlantilla}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        let plantillaValores = [];

        request.data.data.map((campo, i) => {
          let valor = null;

          if (campo.CodigoTipoCampo == 3) {
            //Es un campo de tipo fecha y ponemos default hoy
            const hoy = new Date();
            const date = hoy.toISOString().slice(0, 10);
            valor = date;
          }

          campo.Valor = valor;

          if (campo.ValorPorDefecto != null && campo.CodigoTipoCampo !== 20) {
            campo.Valor = campo.ValorPorDefecto;
          }

          plantillaValores.push(campo);
        });

        this.setState({
          plantilla: request.data.data,
          plantillaValores: plantillaValores,
          isLoadingPlantilla: false,
        });
      } else {
        //El API alerta error.
        this.setState({
          isLoadingPlantilla: false,
        });
      }
    } catch (e) {}
  };

  async cargarDatosProblema() {
    const request = await API.fetchGetRequest(
      "api/problema/cargarDatosEdicion/" + this.state.codigoProblema
    );
    const requestCodGestion = await API.fetchGetRequest(
      `api/vacaciones/getCodigoProblemaVacaciones`
    );

    // if (!Array.isArray(request.data.data)) {
    //   return;
    // }
    let registro = request.data.data;
    let fechaInicioymd =
      registro.FechaInicio == null
        ? null
        : await convertYYYYMMDDToDate(registro.FechaInicio, "-");
    let fechaFinymd =
      registro.FechaFin == null
        ? null
        : await convertYYYYMMDDToDate(registro.FechaFin, "-");
    let tiempoEsperaEstimadoSegundos = registro.TiempoEsperaEstimado;
    let tiempoEsperaEstimadoHHmm = convertirHHMM(tiempoEsperaEstimadoSegundos);
    console.log("Plantilla notify", registro.CodigoPlantillaNotificaciones);
    let problema = {
      codigoProblema: registro.IdObjeto,
      codigoSubEntidad: registro.CodigoEntidad,
      codigoPlantilla: registro.CodigoPlantilla,
      nombre: registro.Nombre,
      descripcion: registro.Descripcion,
      popular: registro.Popular,
      tiempoEsperaEstimado: tiempoEsperaEstimadoHHmm,
      fechaInicio: fechaInicioymd,
      fechaFin: fechaFinymd,
      esEditable: registro.EsEditable,
      activo: registro.Activo,
      titulo: registro.Nombre,
      verFechaVencimiento: registro.AplicaFechaVencimiento,
      muestraFechaVencimientoCreador: registro.MuestraFechaVencimientoCreador,
      codigoVacaciones: requestCodGestion.data.data,
      codigoPlantillaNotificaciones: registro.CodigoPlantillaNotificaciones,
    };

    this.setState(
      {
        //codigoProblema: registro.CodigoProblema,
        codigoPlantilla: registro.CodigoPlantilla,
        tiempoEsperaEstimadoSegundos: tiempoEsperaEstimadoSegundos,
        problema: problema,
        codigoPlantillaTareas: registro.CodigoPlantillaTareas,
        codigoEntidad: registro.CodigoEntidad,
        tiempoEsperaEstimado: tiempoEsperaEstimadoHHmm,
        titulo: registro.Nombre,
        nombreProblema: registro.Nombre,
        verFechaVencimiento: registro.AplicaFechaVencimiento,
        muestraFechaVencimientoCreador: registro.MuestraFechaVencimientoCreador,
        codigoVacaciones: requestCodGestion.data.data,
        codigoPlantillaNotificaciones: registro.CodigoPlantillaNotificaciones,
      },

      () => {}
    );
  }

  onSelectedItemsCamposDinamicos = (campo) => (event) => {
    console.log("Onchange");
    let { plantillaValores } = this.state;
    console.log(event.target);

    let options = event.target.value;
    console.log(options);
    if (!Array.isArray(options)) {
      options = [options];
    }

    const val = {
      CodigoCampo: campo.CodigoCampo,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      Valor: options,
      Requerido: campo.Requerido,
      CodigoCampoPadre: campo.CodigoCampoPadre,
      LlaveCampo: campo.LlaveCampo,
    };

    const find = plantillaValores.findIndex(
      (f) => f.CodigoCampo == campo.CodigoCampo
    );

    if (find >= 0) {
      plantillaValores[find] = val;
    } else {
      plantillaValores.push(val);
    }

    this.setState({ plantillaValores });
    this.verificarDependencias(val);
  };

  grabarFila = async (codigoCampoBase, campoGenericForm, filaObject) => {
    console.log("grabarFila de form genérico");
    let { plantillaValores } = this.state;

    let campo;

    let index = plantillaValores.findIndex(
      (f) => f.CodigoCampo == codigoCampoBase
    );

    if (index >= 0) {
      campo = plantillaValores[index];
    }

    // array de filas ya formateadas
    let filas = plantillaValores[index].Filas;

    //Agrega nuevas filas
    filas = Array.isArray(filas) ? filas : [];
    filas.push({ ...filaObject });

    //array de los valores
    let valores = plantillaValores[index].Valor;

    //agrega nuevos valores
    valores = Array.isArray(valores) ? valores : [];
    valores.push(...campoGenericForm);

    if (index >= 0) {
      plantillaValores[index] = { ...campo, Valor: valores, Filas: filas };
    }

    await this.setState({ plantillaValores });
  };

  removerFila = async (objectToDelete, codigoFormDeCampo, codigoCampo) => {
    console.log("objectToDelete", objectToDelete);

    //el registro aún no existe en DB y solo debe removerse de memoria

    let plantillaValoresCopy = this.state.plantillaValores;
    console.log("plantillaValoresCopyBefore", plantillaValoresCopy);

    let index = plantillaValoresCopy.findIndex((x) => {
      //codigoCampo parametro es el código del campo tipo grid
      return x.CodigoCampo === codigoCampo;
    });

    if (index < 0) {
      return;
    }

    let campo = plantillaValoresCopy.find((x) => {
      return x.CodigoCampo === codigoCampo;
    });

    //Filas
    let filasGrid = Array.isArray(campo.Filas) ? campo.Filas : [];
    console.log("FilasGrid", filasGrid);

    //Fila que se va a remover
    let localRowRemove = filasGrid.find((x) => {
      return x.codigo === objectToDelete.codigo;
    });
    console.log("localRowRemove", localRowRemove);

    //Remuevo fila sacándola del nuevo array
    let nuevaListaFilas = filasGrid.filter((el) => {
      return el !== localRowRemove;
    });
    console.log("Nueva lista filas", nuevaListaFilas);

    //Borra campos de grid que tienen el mismo código local
    let listaValoresTipoCampo = campo.Valor;

    //descarta del nuevo array la lista de campos que tienen el mismo campo local
    let nuevaListaValoresTipoCampo = listaValoresTipoCampo.filter((el) => {
      return el.codigoLocal === objectToDelete.codigo;
    });

    //asigna nuevo array de filas
    campo.Filas = nuevaListaFilas;
    campo.Valor = nuevaListaValoresTipoCampo;

    plantillaValoresCopy[index] = campo;
    this.setState({ plantillaValores: plantillaValoresCopy });
  };

  handleChangeFiles(campo, images) {
    console.log(images);
    console.log(campo);
    let { plantillaValores } = this.state;

    const find = plantillaValores.findIndex(
      (f) => f.CodigoCampo == campo.CodigoCampo
    );

    if (find >= 0) {
      let val = plantillaValores[find];

      let imagenes = images.map((image) => image.path);
      val.AttrVista = imagenes;

      //let files = [];
      let files = images;

      //images.map((image, idx) => {
      //let pathParts = ""; // image.path.split("/");
      // files.push({
      //   uri: image.path,
      //   type: image.type,
      //   name: image.name, //[pathParts.length - 1],
      // });
      // });

      val.Valor = files;

      plantillaValores[find] = val;
    }

    this.setState({ plantillaValores });
  }

  dibujarCampo = (row, css) => {
    if (!row.Visible) {
      return;
    }
    let component;
    switch (row.CodigoTipoCampo) {
      case 1:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
                LlaveCampo: row.LlaveCampo,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
            onBlur={() => {
              //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
              let { plantillaValores } = this.state;

              let campo = plantillaValores.find(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              this.verificarDependencias(campo);
            }}
          />
        );

        break;

      case 2:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            type="number"
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            onChange={(event) => {
              console.log("HOSTIAS", row);

              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
                LlaveCampo: row.LlaveCampo,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
            onBlur={() => {
              //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
              let { plantillaValores } = this.state;

              let campo = plantillaValores.find(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              this.verificarDependencias(campo);
            }}
          />
        );
        break;

      case 3:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <TextField
                required={row.Requerido}
                id={row.Nombre}
                label={row.Nombre}
                type="date"
                defaultValue={new Date()}
                style={{ width: "100%" }}
                // value={this.state["campo" + row.CodigoCampo.toString()]}
                // onChange={this.handleChange(
                //   "campo" + row.CodigoCampo.toString()
                // )}
                onChange={(event) => {
                  const { value } = event.target;
                  let { plantillaValores } = this.state;

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: value,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                    LlaveCampo: row.LlaveCampo,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        );
        break;
      case 4:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            multiline
            rows={5}
            rowsMax={30}
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
                LlaveCampo: row.LlaveCampo,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
          />
        );
        break;

      case 5:
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="">{row.Nombre}</InputLabel>
                <Select
                  // multiple
                  name={"select" + row.Nombre}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                  value={selectedItems[0]}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                >
                  {row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;

      case 6:
        component = (
          <FormControl style={{ width: "100%" }} required={row.Requerido}>
            <label>{row.Nombre}</label>
            <DropzoneArea
              dropzoneText={row.Nombre}
              onChange={this.handleChangeFiles.bind(this, row)}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
              maxFileSize={1073741824}
              filesLimit={100}
            />
          </FormControl>
        );
        break;
      case 7:
        component = (
          <FormControlLabel
            required={row.Requerido}
            control={
              <Checkbox
                required={row.Requerido}
                onChange={(event) => {
                  const { checked } = event.target;
                  let { plantillaValores } = this.state;

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: checked,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                    LlaveCampo: row.LlaveCampo,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                //checked={this.state["campo" + row.CodigoCampo.toString()]}
                // onChange={this.handleChangeCheck(
                //   "campo" + row.CodigoCampo.toString()
                // )}
                // checked={true}
                // onChange={this.handleChange("activo")}

                value="activo"
                color="primary"
              />
            }
            label={row.Nombre}
          />
        );
        break;

      case 8:
        component = (
          <FormControl style={{ width: "100%" }} required={row.Requerido}>
            <label>{row.Nombre}</label>
            <DropzoneArea
              dropzoneText={row.Nombre}
              onChange={this.handleChangeFiles.bind(this, row)}
              maxFileSize={1073741824}
              acceptedFiles={[
                "image/jpeg",
                "image/png",
                "image/bmp",
                ".sql",
                ".txt",
                ".pdf",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
                ".csv",
                ".ppt",
                ".pptx",
                ".pages",
                ".odt",
                ".rtf",
                ".ai",
                ".psd",
                ".mp3",
                ".mp4",
                ".avi",
                ".eps",
                ".ait",
                ".svg",
                ".svgz",
              ]}
              filesLimit={100}
            />
          </FormControl>
        );
        break;

      case 10: {
        //Picklist SAP
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }

        let itemsCopy = [];
        row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });
        console.log("itemscopy", itemsCopy);

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="grouped-select">{row.Nombre}</InputLabel>
                <Select
                  //defaultValue=
                  value={selectedItems}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                >
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={item.id === 0 ? css.group : css.item}
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 12: {
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }

        let itemsCopy = [];
        row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="grouped-select">{row.Nombre}</InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  value={selectedItems}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                >
                  {/* {row.Items.map((item) => (
                    <React.Fragment>
                      <ListSubheader>{item.name}</ListSubheader>

                      {item.children.map((obj) => (
                        <MenuItem key={obj.id} value={obj.id}>
                          {obj.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ))} */}
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={item.id === 0 ? css.group : css.item}
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 13:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
          />
        );

        break;
      //Campo de tipo Hora
      case 14:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                {row.Nombre} (HH:mm)
              </InputLabel>
              <Input
                required={row.Required}
                //value={this.state["campo" + row.CodigoCampo.toString()]}
                onChange={(event) => {
                  const { value } = event.target;
                  let { plantillaValores } = this.state;
                  let partes, valor;

                  partes = value.split(":");
                  console.log("partes:", partes.length);

                  valor = {
                    horas: partes[0].replace("  ", "00"),
                    minutos: partes[1].replace("  ", "00"),
                  };

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: valor,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                    LlaveCampo: row.LlaveCampo,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                id={row.CodigoCampo}
                inputComponent={TextMaskCustom}
              />
            </FormControl>
          </Grid>
        );
        break;
      //Campo de tipo Separador 01
      case 15:
        component = (
          // <Separador CodigoCampo={row.CodigoCampo} Nombre={row.Nombre} />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        );
        break;
      case 16: //Un titulo
        component = (
          <div className={css.inputField}>
            <Typography variant="h6" gutterBottom>
              {row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 17: //Una descripción
        component = (
          <div className={css.inputField}>
            <Typography variant="body2" gutterBottom>
              {row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 18: //Imagen fija
        component = (
          <div
            className={css.inputField}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img alt="img" src={row.Nombre} style={{ maxWidth: "100%" }} />
          </div>
        );
        break;
      case 19:
        {
          let find = this.state.plantillaValores.find(
            (f) => f.CodigoCampo == row.CodigoCampo
          );

          let selectedItems = [];
          if (find && find.Valor) {
            selectedItems = find.Valor;
          } else {
            selectedItems = [];
          }
          component = (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }} required={row.Requerido}>
                  <InputLabel htmlFor="">{row.Nombre}</InputLabel>
                  <Select
                    multiple
                    name={"select" + row.Nombre}
                    input={
                      <Input
                        name={"select" + row.Nombre}
                        id={"select" + row.Nombre}
                      />
                    }
                    value={selectedItems}
                    onChange={this.onSelectedItemsCamposDinamicos(row)}
                  >
                    {row.Items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        break;
      case 20:
        {
          let find = this.state.plantillaValores.find(
            (f) => f.CodigoCampo == row.CodigoCampo
          );

          let selectedItems = [];
          if (find && find.Valor) {
            selectedItems = find.Valor;
          } else {
            selectedItems = [];
          }

          let filas = [];
          if (find && find.Filas) {
            find.Filas.forEach((el, i) => {
              filas.push(el);
            });
            //filas = find.Filas;
          } else {
            filas = [];
          }

          component = (
            <Grid container spacing={3}>
              <div>
                <strong>{row.Nombre}</strong>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                  columns={row.Items}
                  rows={filas}
                  codigoFormDeCampo={Number(row.ValorPorDefecto)}
                  codigoCampo={row.CodigoCampo}
                  codigoLocal={this.state.codigoLocal}
                  grabarFila={this.grabarFila}
                  removerFila={this.removerFila}
                  incrementarCodigoLocal={this.incrementarCodigoLocal}
                />
              </Grid>
            </Grid>
          );
        }

        break;
      case 21: //Campo Calculado
        /* 
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <FunctionsIcon />
              </InputAdornment>
            }
          /> */

        component = (
          <div className={css.flexRow}>
            <FunctionsIcon style={{ color: "rgb(33, 150, 243)" }} />
            <TextField
              disabled={true}
              id={`txt${row.CodigoCampo}`}
              type="number"
              label={
                row.Nombre +
                ": Este campo se calcula cuando se guarda la gestión."
              }
              style={{ width: "100%" }}
              margin="normal"
              variant="outlined"
              inputProps={{ zIndex: 0 }}
              placeholder={"Este campo se calcula cuando se guarda la gestión."}
              startAdornment={
                <InputAdornment position="start">
                  <FunctionsIcon />
                </InputAdornment>
              }
            />
          </div>
        );
        break;
    }
    return component;
  };

  handleChange = (name) => (event) => {
    if (name == "activo") {
      this.setState({ [name]: event.target.checked });
    } else if (name == "fechasVacaciones") {
      if (this.state.diasMaximosVacaciones < event.length) {
        toast.warn(
          "Usted no dispone de vaciones libres, cuenta con " +
            this.state.diasMaximosVacaciones +
            " días y ha seleccionado " +
            event.length
        );
        return;
      } else {
        let esUnaVacacionMas = false;
        if (this.state.fechasVacaciones.length < event.length) {
          esUnaVacacionMas = true;
        }
        if (
          !(
            !esUnaVacacionMas &&
            this.state.diasMaximosVacaciones == event.length
          )
        ) {
          this.setState({
            [name]: this.convertirFechas(event),
            distribucionVacaciones: this.repartirVacaciones(esUnaVacacionMas),
          });
        }
      }
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };
  convertirFechas(array) {
    let fechas = [];
    let formatofecha = "";
    array.forEach((result) => {
      formatofecha =
        result.year + "-" + (result.month.index + 1) + "-" + result.day;
      fechas.push(formatofecha);
    });
    return fechas.sort((a, b) => new Date(a) - new Date(b));
  }

  repartirVacaciones(esUnaVacacionMas) {
    let CuentaDia = false;
    let nuevoArrayVacaciones = [];
    // Quitar un día de vacaciones de un periodo (un dia contado)
    if (esUnaVacacionMas) {
      this.state.distribucionVacaciones.forEach((element) => {
        if (element.DiasPendientes > 0 && !CuentaDia) {
          element.DiasPendientes--;
          element.DiasGozados++;
          CuentaDia = true;
          nuevoArrayVacaciones.push(element);
          return;
        }
        nuevoArrayVacaciones.push(element);
      });
    } else {
      // Deseleccionar un día de vacaciones
      this.state.distribucionVacaciones
        .slice()
        .reverse()
        .forEach((element) => {
          if (element.DiasGozados > 0 && !CuentaDia) {
            element.DiasPendientes++;
            element.DiasGozados--;
            CuentaDia = true;
            nuevoArrayVacaciones.push(element);
            return;
          }
          nuevoArrayVacaciones.push(element);
        });
    }
    return nuevoArrayVacaciones.sort(
      (a, b) => a.CodigoPeriodoVacacion + b.CodigoPeriodoVacacion
    );
  }

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  irConfiguracion = (codigo) => {
    this.props.history.push("/problem/" + codigo);
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  cargarinfoVacaciones = async (actual) => {
    let usuarioDPI = actual.Usuario;
    const request = await API.fetchGetRequest(
      `api/vacaciones/listarVacacionesScilu/${usuarioDPI}`
    );
    let maxdias = 0;
    let periodosAntiguos = [];
    if (!request.data.response) {
      toast.error(
        "El usuario no inició sesión con un DPI, no puede desplegarse información"
      );
    } else {
      if (request.data.data.length === 0) {
        toast.warn("El usuario no cuenta con información sobre vacaciones");
      } else {
        request.data.data.forEach((element) => {
          maxdias += element.DiasPendientes;
          periodosAntiguos.push({
            DiasGozados: element.DiasGozados,
            DiasPendientes: element.DiasPendientes,
          });
        });
      }
    }
    this.setState(
      {
        vacacionesPeriodos: periodosAntiguos,
        distribucionVacaciones: request.data.data,
        diasMaximosVacaciones: maxdias,
      },
      () => {
        this.setState({ activarDatePicker: true });
      }
    );
  };

  cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState(
          {
            listaUsuarios: request.data.usuarios,
          },
          () => {
            //Obtiene usuario actual
            let actual = this.state.listaUsuarios.filter((usuario) => {
              return (
                usuario.CodigoUsuario ===
                parseInt(this.state.codigoUsuarioActual)
              );
            });
            if (actual.length < 1) {
              return;
            } else {
              actual = actual[0];
            }
            console.log("usuarioActual", actual.NombreCompletoUsuario);
            this.setState({ usuarioActual: actual });
            if (this.state.codigoProblema === this.state.codigoVacaciones) {
              this.cargarinfoVacaciones(actual);
            }
          }
        );
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  makeRequestGuardar = async () => {
    try {
      var response = false;
      this.setState({
        procesando: true,
        response: false,
        creacionAvisoCompletada: false,
      });

      //this.setState({ isPosting: true });
      const {
        codigoVacaciones,
        titulo,
        descripcion,
        usuarioAfectado,
        plantillaValores,
        location,
        codigoProblema,
        fechaVencimiento,
        fechasVacaciones,
        diasMaximosVacaciones,
        codigoPlantillaNotificaciones,
        dataUsuario,
      } = this.state;
      const validacionRequeridos =
        await this.validarCamposPersonalizadosRequeridos();
      if (codigoProblema === codigoVacaciones) {
        if (fechasVacaciones == null || fechasVacaciones.length === 0) {
          toast.warn("Debe seleccionar al menos una fecha para vacaciones");
          this.setState({ procesando: false, response: false });
          response = false;
          return response;
        }
        if (diasMaximosVacaciones < fechasVacaciones.length) {
          toast.error(
            "Está excediendo su límite de días de vacaciones, dispone de " +
              diasMaximosVacaciones +
              " días, y está seleccionando " +
              fechasVacaciones.length +
              " días"
          );
          this.setState({ procesando: false, response: false });
          response = false;
          return response;
        }
      }
      if (!validacionRequeridos) {
        toast.warn("Llena todos los campos requeridos");
        this.setState({ procesando: false, response: false });
        response = false;
        return response;
      }

      if (titulo.trim() === "") {
        toast.warn("Ingresa un título");
        this.setState({ procesando: false, response: false });
        response = false;
      } else {
        const session = await isSignedIn();

        if (session.response) {
          const objeto = {
            CodigoProblema: codigoProblema,
            CodigoSolicitante: session["codigoUsuario"],
            Titulo: titulo,
            Descripcion: "",
            UsuarioInserto: session["userName"],
            CodigoUsuarioAfectado: dataUsuario.CodigoUsuario,
            PlantillaValores: plantillaValores,
            Longitud: location.lon,
            Latitud: location.lat,
            FechaVencimiento: fechaVencimiento,
            CodigoPlantillaNotificacion:
              codigoPlantillaNotificaciones === 0
                ? null
                : codigoPlantillaNotificaciones,
          };

          console.log("Lo que va a guardar", objeto);

          const request = await API.post("/api/aviso", objeto);
          console.log("aviso", request);
          if (request.status === 401) {
            //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
            if (await signOut()) {
              window.location.url = "/Login";
            }
            this.setState({ response: false, creacionAvisoCompletada: false });
            return false;
          } else {
            //El servidor nos devolvio que se inserto.

            //Enviar al server las imagenes/documentos del aviso.

            //this.props.history.push("/bandeja");
            this.setState({
              procesando: true,
              response: true,
              creacionAvisoCompletada: true,
            });
            await this.uploadFiles(request.data.data.id);
            response = true;
            if (codigoProblema == codigoVacaciones) {
              await this.ingresarVacaciones(request.data.data.id);
              response = true;
              return response;
            }

            //toast.info(request.data.mensaje);
            // setTimeout(()=>{this.props.history.push("/bandeja")}, 5000);
          }
        } else {
          toast.warn(
            "Hubo un problema al crear tu aviso, consulta con tu administrador"
          );
          response = false;
          this.setState({
            procesando: false,
            response: false,
            creacionAvisoCompletada: false,
          });
        }
      }

      this.setState({ isPosting: false });

      return response;
    } catch (err) {
      console.log("Grabar: " + err);
    } finally {
      if (
        this.state.response === true &&
        this.state.creacionAvisoCompletada === true &&
        this.state.cantidadArchivosAdjuntar < 1
      ) {
        toast.info("La gestión se ha creado exitosamente");
        setTimeout(() => {
          this.props.history.push("/home");
        }, 3000);
        this.setState({
          procesando: false,
          response: false,
          creacionAvisoCompletada: false,
        });
      } else if (
        this.state.response === false &&
        this.state.creacionAvisoCompletada === false &&
        this.state.cantidadArchivosAdjuntar > 0
      ) {
        toast.info("Ocurrió un problema, inténtalo nuevamente");
        this.setState({
          procesando: false,
          response: false,
          creacionAvisoCompletada: false,
        });
      }
    }
  };

  redireccionar = (url) => {
    this.props.history.push(url);
  };

  cargarPlantillaValores = async () => {
    let plantillaValores = [];

    //recorre el array de campos y agrega el valor
    this.state.campos.forEach((item, index) => {
      let newItem = {
        CodigoCampo: item.CodigoCampo,
        CodigoCampoPadre: item.CodigoCampoPadre,
        CodigoTipoCampo: item.CodigoTipoCampo,
        Requerido: item.Requerido,
        Valor: this.state["campo" + item.CodigoCampo.toString()],
        Nombre: item.Nombre,
      };
      plantillaValores.push(newItem);
    });

    this.setState({ plantillaValores: plantillaValores });
  };

  validarCamposPersonalizadosRequeridos = async () => {
    //Retorna true si todos los campos requeridos han sido llenados, retorna false si aún falta rellenar datos.
    this.setState({ openAlert: false });

    let { plantillaValores } = this.state;
    let response = true;

    let camposObligatoriosVacios = [];

    await this.setState({
      camposObligatoriosVacios: camposObligatoriosVacios,
    });

    console.log("los valores que va a validar", plantillaValores);

    await plantillaValores.map((campo, i) => {
      if (
        campo.CodigoTipoCampo != 3 &&
        campo.CodigoTipoCampo != 7 &&
        campo.Requerido
      ) {
        //Los campo tipo checkbox y datetime no se validan

        if (campo.CodigoTipoCampo == 10) {
          console.log(campo.Valor, campo.Valor.length);
        }
        if (
          campo.Valor == null ||
          (campo.CodigoTipoCampo == 1 && campo.Valor.trim() == "") ||
          (campo.CodigoTipoCampo == 4 && campo.Valor.trim() == "") ||
          (campo.CodigoTipoCampo == 10 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 12 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 5 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 2 && campo.Valor.length == 0)
        ) {
          response = false;
          camposObligatoriosVacios.push(campo.Nombre);
        }
      }
    });

    await this.setState({ camposObligatoriosVacios });
    if (camposObligatoriosVacios.length > 0) {
      // window.alert(
      //   "debe llenar los siguientes campos: <br> " + camposObligatoriosVacios
      // );
      this.setState({ openAlert: true });
      document.getElementById("alert").scrollIntoView();
    }

    return response;
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let location = this.state.location;
        location.lon = position.coords.longitude;
        location.lat = position.coords.latitude;
        this.setState({ location: location });
      });
    } else {
      toast.warn = "Su navegador no soporta geolocalización";
    }
  };

  uploadFiles = async (codigoAviso) => {
    const { plantillaValores } = this.state;
    const session = await isSignedIn();
    const objeto = {
      CodigoSolicitante: session["codigoUsuario"],
      UsuarioInserto: session["userName"],
      PlantillaValores: plantillaValores,
    };

    const camposConArchivos = plantillaValores.filter((obj) => {
      return obj.CodigoTipoCampo === 6 || obj.CodigoTipoCampo === 8;
    });

    await camposConArchivos.forEach(async (element, i) => {
      if (element.Valor != null && element.Valor.length > 0) {
        await this.setState({
          cantidadArchivosAdjuntar: this.state.cantidadArchivosAdjuntar + 1,
        });
      }
    });

    console.log(
      "Total archivos a adjuntar",
      this.state.cantidadArchivosAdjuntar
    );

    //Inicio sync
    await camposConArchivos.forEach(async (element, i) => {
      if (element.Valor != null && element.Valor.length > 0) {
        let data = new FormData();
        data.append("CodigoAviso", codigoAviso);
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoCampo", element.CodigoCampo);
        data.append("CodigoTipoCampo", element.CodigoTipoCampo);

        await element.Valor.forEach(async (element2, j) => {
          await data.append("files[]", element2);
        });

        console.log("data Multimedia " + i, data);

        let request = await API.post("/api/aviso/agregarMultimedia", data, {
          "content-type": "multipart/form-data",
        });
        this.setState(
          { numeroIteracionAdjunto: this.state.numeroIteracionAdjunto + 1 },
          () => {
            if (
              this.state.cantidadArchivosAdjuntar ===
              this.state.numeroIteracionAdjunto
            ) {
              toast.info("La gestión se ha creado exitosamente");
              setTimeout(() => {
                this.props.history.push("/bandeja");
              }, 5000);
              console.log("Todos los archivos han sido cargados");
            }
          }
        );
        console.log(i + request);
      }
    });
  };

  ingresarVacaciones = async (codigoAviso) => {
    const {
      fechasVacaciones,
      usuarioActual,
      distribucionVacaciones,
      vacacionesPeriodos,
    } = this.state;
    let arraySolicitud = [];
    let idPeriodoVacaciones = 0;
    distribucionVacaciones.forEach((element) => {
      let objeto = {
        CodigoAviso: codigoAviso,
        Periodo: element.Descripcion,
        Otorgadas: element.DiasAsignados,
        GozadasAnteriormente:
          vacacionesPeriodos[idPeriodoVacaciones].DiasGozados,
        Solicitadas:
          vacacionesPeriodos[idPeriodoVacaciones].DiasPendientes -
          element.DiasPendientes,
        TotalGozadas: element.DiasGozados,
        PendientesGozar: element.DiasPendientes,
        Activo: true,
        Eliminado: false,
        UsuarioInserto: usuarioActual.Usuario,
        UsuarioModifico: "",
      };
      idPeriodoVacaciones++;
      arraySolicitud.push(objeto);
    });
    const objeto = {
      ListaSolicitudVacaciones: arraySolicitud,
      ListaFechaVacaciones: {
        CodigoAviso: codigoAviso,
        Fecha: fechasVacaciones,
        Duracion: 1,
        Activo: true,
        Eliminado: false,
        UsuarioInserto: usuarioActual.Usuario,
        UsuarioModifico: "",
      },
    };
    const request = await API.post(
      "/api/vacaciones/grabarDatosVacaciones",
      objeto
    );
    if (request.status === 401) {
      //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
      if (await signOut()) {
        window.location.url = "/Login";
      }
      this.setState({ response: false, creacionAvisoCompletada: false });
      return false;
    }
  };

  verificarDependencias = async (campo) => {
    let { plantillaValores, plantilla } = this.state;

    let find = plantillaValores.filter(
      (f) => f.CodigoCampoPadre == campo.CodigoCampo
    );

    if (find.length > 0) {
      //Mandar a traer al api los valores de cada uno de los campos dependientes
      await find.map(async (campoALlenar, i) => {
        const uri =
          campoALlenar.LlaveCampo == "picklistSap"
            ? `api/sap/avisos/llenarDropdown/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
            : campoALlenar.LlaveCampo == "picklistSapTree"
            ? `api/sap/avisos/llenarDropdownParentChild/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
            : "";

        let request = await API.fetchGetRequest(uri);

        let findCampoPlantillaIndex = plantilla.findIndex(
          (f) => f.CodigoCampo == campoALlenar.CodigoCampo
        );
        let findCampoPlantilla = plantilla[findCampoPlantillaIndex];
        findCampoPlantilla.Items = request.data.data;

        //Seteamos el valor default del campo a plantillaValores
        campoALlenar.Valor = request.data.defaultValue;
        const findPlantillaValoresIndex = plantillaValores.findIndex(
          (f) => f.CodigoCampo == campoALlenar.CodigoCampo
        );
        plantillaValores[findPlantillaValoresIndex] = campoALlenar;

        plantilla[findCampoPlantillaIndex] = findCampoPlantilla;

        await this.setState({ plantilla });
        await this.setState({ plantillaValores });
        console.log(request);
      });
    }
  };

  incrementarCodigoLocal = () => {
    //CodigoLocal es un identificador temporal (en memoria) para los registros que se crean en tiempo de ejecución
    let cod = this.state.codigoLocal;
    cod++;
    this.setState({ codigoLocal: cod });
  };

  render() {
    const { classes } = this.props;

    console.log("u.u", this.state.problema);
    return (
      <Layout titulo="Crear consulta médica">
        {this.state.cargando && this.state.problema !== null ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        -
        <Container maxWidth="md">
          <div
            style={{
              color: "#2196F3",
              display: "flex",
              alignContent: "center",
              fontSize: "1m",
              marginTop: 10,
            }}
          >
            <Typography variant="h5" gutterBottom>
              {this.state.nombreProblema}
            </Typography>
          </div>
          <div
            style={{
              color: "#2196F3",
              display: "flex",
              alignContent: "center",
              fontSize: "1m",
              marginTop: 10,
            }}
          >
            <br />
            <div>
              <AccessTimeIcon style={{ color: "#2196F3", marginRight: 10 }} />
              {"Tiempo estimado de espera: " + this.state.tiempoEsperaEstimado}
            </div>
          </div>
          <br />
          <br />

          <Grid container spacing={3}>
            {this.state.encontrado === true && (
              <DatosEmpleado dataEmpleado={this.state.dataEmpleado} />
            )}
            <Grid item xs={12} lg={12} sm={12}>
              <TextField
                id="titulo"
                label="Título"
                required
                style={{ width: "100%" }}
                // value={`${this.state.titulo} - Paciente: ${
                //   this.state.dataEmpleado
                //     ? this.state.dataEmpleado.NombreEmpleado
                //     : ""
                // }`}
                value={`${this.state.titulo}`}
                defaultValue={`${this.state.nombreProblema}`}
                margin="normal"
                variant="outlined"
                inputProps={{ zIndex: 0 }}
                onChange={this.handleChange("titulo")}
              />
            </Grid>
            <ExpandableComponent title="Información de la Consulta">
              {/* <Grid item xs={12} lg={12} sm={12}>
              <TextField
                id={`descripcion`}
                label="Descripción"
                required
                style={{ width: "100%" }}
                value={this.state.descripcion}
                onChange={this.handleChange("descripcion")}
                margin="normal"
                variant="outlined"
                inputProps={{ zIndex: 0 }}
                multiline
                rows="3"
              />
            </Grid> */}

              {/* {this.state.verFechaVencimiento &&
              this.state.muestraFechaVencimientoCreador && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="FechaVencimiento"
                    label="Fecha vencimiento"
                    type="date"
                    defaultValue=""
                    value={this.state.fechaVencimiento}
                    className={classes.textField}
                    onChange={this.handleChange("fechaVencimiento")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )} */}
              {/* {this.state.codigoProblema === this.state.codigoVacaciones &&
            this.state.activarDatePicker ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>
                    Fechas de Vacaciones a solicitar: {"  "}
                    <DatePicker
                      id="FechasVacaciones"
                      multiple
                      minDate={new Date().setDate(new Date().getDate())}
                      plugins={[<DatePanel />]}
                      onChange={this.handleChange("fechasVacaciones")}
                    />
                  </label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    style={{
                      backgroundColor: "#69bd4b",
                      color: "white",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => {
                      this.props.history.push("/vacaciones");
                    }}
                  >
                    {" "}
                    Consultar información de mis Vacaciones <br />
                    (Días totales disponibles:{" "}
                    {this.state.diasMaximosVacaciones -
                      this.state.fechasVacaciones.length}
                    )
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            Fechas Seleccionadas
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.fechasVacaciones.map((row) => (
                          <StyledTableRow key={row}>
                            <StyledTableCell component="th" scope="row">
                              {row.split("-")[2] +
                                "/" +
                                row.split("-")[1] +
                                "/" +
                                row.split("-")[0]}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Periodo</StyledTableCell>
                          <StyledTableCell>Otorgadas</StyledTableCell>
                          <StyledTableCell>Solicitadas</StyledTableCell>
                          <StyledTableCell>Disponibles</StyledTableCell>
                          <StyledTableCell>Gozadas</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.distribucionVacaciones
                          .sort(
                            (a, b) =>
                              a.CodigoPeriodoVacacion - b.CodigoPeriodoVacacion
                          )
                          .map((row) => (
                            <StyledTableRow key={row.Descripcion}>
                              <StyledTableCell component="th" scope="row">
                                {row.Descripcion}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.DiasAsignados}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.DiasGozados}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.DiasPendientes}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.DiasGozados}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
            )} */}

              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6">
                Plantilla Frecuencia de alertas
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: "100%", marginTop: "17px" }}
                  >
                    <Select
                      name="plantillaAlerta"
                      inputRef={this.codigoPlantillaNotificacionesRef}
                      value={this.state.codigoPlantillaNotificaciones}
                      onChange={this.handleChange(
                        "codigoPlantillaNotificaciones"
                      )}
                      input={
                        <Input
                          name="unidadPlantillaNotificaciones"
                          id="unidadPlantillaNotificaciones"
                        />
                      }
                    >
                      {this.state.listaPlantillasAlerta.map((item) => (
                        <MenuItem
                          key={
                            "listaPlantillaAlerta" + item.CodigoPlantillaAlerta
                          }
                          value={item.CodigoPlantillaAlerta}
                        >
                          {item.Nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid> */}

              {this.state.plantilla.map((row, i) => (
                <Grid item xs={12} lg={12} sm={12}>
                  {this.dibujarCampo(row, classes)}
                </Grid>
              ))}
            </ExpandableComponent>
          </Grid>

          {/* <Autocomplete
            //value={this.state.usuarioActual}
            onChange={(event, newValue) => {
              this.setState({
                usuarioActual: newValue ? newValue : -1,
                usuarioAfectado: newValue ? newValue.CodigoUsuario : 0,
              });
            }}
            id="controllable-states-demo"
            options={this.state.listaUsuarios}
            getOptionLabel={(option) => option.NombreCompletoUsuario}
            style={{ width: "100%", margin: 0, marginTop: 25 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Paciente"
                variant="outlined"
              />
            )}
          /> */}

          {/* <Autocomplete
            required
            className={classes.textField}
            style={{ width: "100%", margin: 0, marginTop: 25 }}
            options={this.state.listaUsuarios}
            autoHighlight
            getOptionLabel={(option) => option.NombreCompletoUsuario}
            //Aquí está la magia para que aparezca seleccionado el usuario
            // inputValue={
            //   Array.isArray(this.state.usuarioActual)
            //     ? this.state.usuarioActual[0].NombreCompletoUsuario
            //     : ""
            // }
            value = {this.state.usuarioActual}
            disabled={false}
            renderOption={(option) => (
              <React.Fragment>
                <span></span>
                {option.NombreCompletoUsuario}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Usuario afectado"
                variant="outlined"
                required
                // inputProps={{
                //   ...params.inputProps,
                //   autoComplete: "new-password", // disable autocomplete and autofill
                // }}
              />
            )}
            onChange={
            //   ()=>{
            //   this.onChangeAutoComplete()
            // }
              
              
              (event, newInputValue) => {
                this.setState({
                  usuarioAfectado: parseInt(
                    newInputValue ? newInputValue.CodigoUsuario : -1
                  ),
                });
              }
              }
          /> */}

          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} sm={12}>
              <Button
                disabled={this.state.procesando}
                variant="contained"
                style={{ backgroundColor: "#69bd4b", color: "white" }}
                className={classes.buttonAdd}
                onClick={() => this.makeRequestGuardar()}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <Collapse in={this.state.openAlert} style={{ width: "100%" }}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ openAlert: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div>Debe llenar los siguientes campos obligatorios:</div>
              {this.state.camposObligatoriosVacios.map((item, index) => (
                <div>{item}</div>
              ))}
            </Alert>
          </Collapse>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div id="alert"></div>
        </Container>
        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.procesando}
          onClose={() => {}}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <form className={classes.container} noValidate autoComplete="off">
              <div style={{ textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Procesando gestión...
                </Typography>
              </div>

              <Grid container spacing={1}>
                <div
                  style={{
                    width: "100%",
                    marginTop: 10,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader
                    type="Oval"
                    color="#3498db"
                    height="50"
                    width="50"
                    style={{ textAlign: "center" }}
                  />
                </div>
                <Grid sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                  {this.state.creacionAvisoCompletada === false ? (
                    <div>
                      <Typography
                        variant="caption"
                        style={{
                          marginTop: 5,
                          color: "#0984e3",
                          fontSize: "1.2em",
                        }}
                      >
                        Procesando gestión ...
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography
                        variant="caption"
                        style={{
                          marginTop: 5,
                          color: "#00b894",
                          fontSize: "1.2em",
                        }}
                      >
                        Los datos de la gestión han sido creados <CheckIcon />
                      </Typography>
                    </div>
                  )}

                  {this.state.creacionAvisoCompletada === true &&
                    this.state.cantidadArchivosAdjuntar >
                      this.state.numeroIteracionAdjunto && (
                      <div>
                        <Typography
                          variant="caption"
                          style={{ color: "#0984e3", fontSize: "1.2em" }}
                        >
                          Subiendo archivos adjuntos, puede demorar algunos
                          minutos...
                        </Typography>
                      </div>
                    )}

                  {this.state.cantidadArchivosAdjuntar ===
                    this.state.numeroIteracionAdjunto &&
                    this.state.cantidadArchivosAdjuntar > 0 && (
                      <div>
                        <Typography
                          variant="caption"
                          style={{ color: "#00b894", fontSize: "1.2em" }}
                        >
                          Carga de archivos completada. <CheckIcon />
                        </Typography>
                      </div>
                    )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}
const CrearAvisoContainer = withStyles(styles)(CrearAviso);
export default withRouter(CrearAvisoContainer);
