import React from "react";
import { Avatar, Chip, Tooltip } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#2196F3",
    color: "white",
    fontSize: 10,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const UsuariosResumen = ({ usuarios }) => {
  const classes = useStyles();

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          {usuarios.map((usuario, i) => (
            <Chip
              key={i}
              size="small"
              avatar={<Avatar>{usuario.substring(0, 1).toUpperCase()}</Avatar>}
              label={
                usuario.length > 19 ? usuario.substring(0, 19) + ".." : usuario
              }
            />
          ))}
        </React.Fragment>
      }
    >
      <AvatarGroup classes={{ avatar: classes.smallAvatar }} max={4}>
        {usuarios.map((usuario, i) => (
          <Avatar key={i} className={classes.smallAvatar}>
            {usuario.substring(0, 1).toUpperCase()}
          </Avatar>
        ))}
      </AvatarGroup>
    </HtmlTooltip>
  );
};

export default UsuariosResumen;
