import React, { useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AvatarGroup } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import UserInfo from "../../usuario-info/containers/user-info";

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#2196F3",
    color: "white",
    fontSize: 10,
  },
  paperUserModal: {
    position: "absolute",
    // width: "75%",
    // height: "600px",
    // maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const UsuariosResumenEditable = ({ usuarios, removerUsuario }) => {
  const classes = useStyles();
  // console.log("UsuariosResumenEditable", usuarios);

  const [open, setOpen] = useState(false);
  const [openModalUsuarioConfig, setOpenModalUsuarioConfig] = useState(false);

  const [currentUserEditName, setCurrentUserEditName] = useState("");
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={"Click para ver más"} placement="bottom-start">
        <div onClick={openModal} style={{ cursor: "pointer" }}>
          <AvatarGroup classes={{ avatar: classes.smallAvatar }} max={4}>
            {usuarios.map((usuario, i) => (
              <Avatar key={i} className={classes.smallAvatar}>
                {usuario.NombreCompletoUsuario.substring(0, 1).toUpperCase()}
              </Avatar>
            ))}
          </AvatarGroup>
        </div>
      </Tooltip>
      <Modal
        aria-labelledby="Listado de Usuarios"
        aria-describedby="Listado de Usuarios"
        open={open}
        onClose={closeModal}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          {usuarios.map((usuario, i) => (
            <UserInfo userName={usuario.Usuario} imagenPerfil={null}>
              <Chip
                key={i}
                size="small"
                avatar={
                  <Avatar>
                    {usuario.NombreCompletoUsuario.substring(
                      0,
                      1
                    ).toUpperCase()}
                  </Avatar>
                }
                label={
                  usuario.NombreCompletoUsuario.length > 19
                    ? usuario.NombreCompletoUsuario.substring(0, 19) + ".."
                    : usuario.NombreCompletoUsuario
                }
                onDelete={() => {
                  removerUsuario(usuario);
                }}
              />
            </UserInfo>
          ))}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UsuariosResumenEditable;
