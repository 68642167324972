import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
//Select
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ReactSketchCanvas } from "react-sketch-canvas";

//Upload file
import { DropzoneArea } from "material-ui-dropzone";

//Checkbox
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { arrayMoveControls } from "../../utils/dndUtils";
import "../components/styles.css";

//Drawer
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import ArrowDropDownCircleTwoToneIcon from "@material-ui/icons/ArrowDropDownCircleTwoTone";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import DescriptionIcon from "@material-ui/icons/Description";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import Filter1Icon from "@material-ui/icons/Filter1";
import FunctionsIcon from "@material-ui/icons/Functions";
import GestureIcon from "@material-ui/icons/Gesture";
import GridOnIcon from "@material-ui/icons/GridOn";
import ImageIcon from "@material-ui/icons/Image";
import InputIcon from "@material-ui/icons/Input";
import MessageIcon from "@material-ui/icons/Message";
import LibraryAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import TitleIcon from "@material-ui/icons/Title";
import TodayIcon from "@material-ui/icons/Today";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import clsx from "clsx";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DehazeIcon from "@material-ui/icons/Dehaze";

//Tabs
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

//chip
import ChipInput from "material-ui-chip-input";

//Scroll bar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import Skeleton from "@material-ui/lab/Skeleton";

//Modal
import Modal from "@material-ui/core/Modal";

//Own components
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormDesignerMirror from "./form-designer";

//Inputs Formulario
import DataGridSample from "../components/data-grid-sample";
import InputCampoCalculado from "../components/template-input/input-campo-calculado";
import InputHora from "../components/template-input/input-hora";
import InputTexto from "../components/template-input/input-texto";
import Separador from "../components/template-input/separador";

//Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Add from "@material-ui/icons/Add";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },

  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },

  paperNuevoForm: {
    position: "absolute",
    width: "50%",
    height: "300px",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  bigPaper: {
    position: "absolute",
    width: "95%",
    height: "95%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const drawerWidth = 260;
const rightDrawerWidth = 200;
const titleColor = "#2B3C4D";

const SortableItem = sortableElement(({ value }) => (
  <li>
    {value}
    <input type="text" />
  </li>
));

const SortableItemObject = sortableElement(
  ({ value, clickFunction, confirmarEliminar, clickSeccion, seccion }) => (
    <li style={{ textAlign: "right" }}>
      <Button
        color="primary"
        onClick={() => clickSeccion(value.CodigoCampo)}
        style={{}}
      >
        {seccion ? "Sección: " + seccion : "Sin sección"}
      </Button>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          clickFunction(value.CodigoCampo);
        }}
      >
        <EditRoundedIcon />
      </IconButton>

      <IconButton
        aria-label="Eliminar"
        style={{ top: "0", color: "#f50057" }}
        onClick={() => confirmarEliminar(value.CodigoCampo)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>

      <div className="dragCard" data-codigocampo={value.CodigoCampo} zIndex="1">
        {inputReturn(
          value.CodigoTipoCampo,
          value.CodigoCampo,
          value.Nombre,
          value.folderPath
        )}
      </div>
    </li>
  )
);

const ModalAskNumber = ({ open, handleClose, handleConfirm }) => {
  const [number, setNumber] = React.useState(0);

  const handleSave = () => {
    handleConfirm(number);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Ingrese sección</DialogTitle>
      <DialogContent>
        <DialogContentText>Sección</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="number"
          label="Number"
          type="number"
          fullWidth
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//Iconos de los campos a agregar.
const iconType = (codigoTipoCampo, style) => {
  let icono = <span />;
  switch (codigoTipoCampo) {
    case 1:
      icono = <InputIcon style={style} />;
      break;
    case 2:
      icono = <Filter1Icon style={style} />;
      break;
    case 3:
      icono = <TodayIcon style={style} />;
      break;

    case 4:
      icono = <ViewHeadlineIcon style={style} />;
      break;

    case 5:
      icono = <ArrowDropDownCircleIcon style={style} />;
      break;

    case 6:
      icono = <ImageIcon style={style} />;
      break;

    case 7:
      icono = <CheckBoxIcon style={style} />;
      break;
    case 8:
      icono = <AttachFileIcon style={style} />;
      break;

    case 10:
      icono = <ArrowDropDownCircleOutlinedIcon style={style} />;
      break;

    case 12:
      icono = <ArrowDropDownCircleTwoToneIcon style={style} />;
      break;

    case 13:
      icono = <MessageIcon style={style} />;
      break;
    case 16:
      icono = <TitleIcon style={style} />;
      break;
    case 17:
      icono = <ListItemIcon style={style} />;
      break;
    case 18:
      icono = <WallpaperIcon style={style} />;
      break;
    case 19:
      icono = <LibraryAddCheckIcon style={style} />;
      break;
    case 20:
      icono = <GridOnIcon style={style} />;
      break;
    case 21: //Campo calculado
      icono = <FunctionsIcon style={style} />;
      break;
    case 22: //APIList
      icono = <ArrowDropDownCircleIcon style={style} />;
      break;
    case 23: //ListaAPI
      icono = <ArrowDropDownCircleIcon style={style} />;
      break;
    case 25: //Sketch
      icono = <GestureIcon style={style} />;
      break;
    default:
      icono = <InputIcon style={style} />;
  }
  return icono;
};

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

//Estos son los inputs que se dibujan dentro del formulario
const inputReturn = (CodigoTipoCampo, CodigoCampo, Nombre, folderPath) => {
  let component;
  switch (CodigoTipoCampo) {
    case 1:
    case 2:
      component = (
        <InputTexto
          CodigoCampo={CodigoCampo}
          Nombre={Nombre}
          Icono={iconType(CodigoTipoCampo, {
            color: "#2196F3",
            marginRight: 10,
          })}
        />
      );

      break;
    case 3:
      component = (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={0}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                disabled
                //   value={this.state.fechaNacimiento}
                //   onChange={this.cambiarFechaNacimiento}
                format="dd/MM/yyyy"
                label={Nombre}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      );
      break;
    case 4:
      component = (
        <TextField
          disabled
          id={`txt${CodigoCampo}`}
          label={Nombre}
          style={{ width: "100%" }}
          value="Lorem ipsum"
          margin="normal"
          variant="outlined"
          inputProps={{ zIndex: 0 }}
          multiline
        />
      );
      break;

    case 5:
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <InputLabel htmlFor="idSubEntidad">{Nombre}</InputLabel>
              <Select
                name={"select" + Nombre}
                // inputRef={this.idSubentidadRef}
                // value={this.state.codigoSubentidadCombo}
                // onChange={this.handleChangeGeneral("codigoSubentidadCombo")}
                input={
                  <Input name={"select" + Nombre} id={"select" + Nombre} />
                }
              ></Select>
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;

    case 6:
      component = (
        <FormControl disabled style={{ width: "100%" }}>
          <label>{Nombre}</label>
          <DropzoneArea
            dropzoneText="Galería de imágenes"
            // onChange={this.handleChange.bind(this)}
          />
        </FormControl>
      );
      break;
    case 7:
      component = (
        <div style={{ textAlign: "left" }}>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={true}
                // onChange={this.handleChange("activo")}
                value="activo"
                color="primary"
              />
            }
            label={Nombre}
          />
        </div>
      );
      break;

    case 8:
      component = (
        <FormControl disabled style={{ width: "100%" }}>
          <label>{Nombre}</label>
          <DropzoneArea
            dropzoneText="Archivos adjuntos"
            // onChange={this.handleChange.bind(this)}
          />
        </FormControl>
      );
      break;

    case 10:
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <InputLabel htmlFor="">{Nombre}</InputLabel>
              <Select
                name={"select" + Nombre}
                // inputRef={this.idSubentidadRef}
                value={1}
                // onChange={this.handleChangeGeneral("codigoSubentidadCombo")}
                input={
                  <Input name={"select" + Nombre} id={"select" + Nombre} />
                }
              >
                <MenuItem key={1} value={1}>
                  {"PickList SAP"}
                </MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;
    case 12:
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <InputLabel htmlFor="">{Nombre}</InputLabel>
              <Select
                name={"select" + Nombre}
                // inputRef={this.idSubentidadRef}
                value={1}
                // onChange={this.handleChangeGeneral("codigoSubentidadCombo")}
                input={
                  <Input name={"select" + Nombre} id={"select" + Nombre} />
                }
              >
                <MenuItem key={1} value={1}>
                  {"PickList SAP Tree"}
                </MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;

    case 13:
      component = (
        <TextField
          disabled
          id={`txt${CodigoCampo}`}
          label={`${Nombre}`}
          style={{ width: "100%" }}
          value="Texto SAP (Extendido)"
          margin="normal"
          variant="outlined"
          inputProps={{ zIndex: 0 }}
        />
      );

      break;
    //Campo de tipo Hora
    case 14:
      component = <InputHora CodigoCampo={CodigoCampo} Nombre={Nombre} />;
      break;
    //Campo de tipo Separador 01
    case 15:
      component = <Separador CodigoCampo={CodigoCampo} Nombre={Nombre} />;
      break;
    case 16:
      component = (
        <div style={{ textAlign: "left" }}>
          <div>
            <strong>Título</strong>
          </div>
          <div>
            <Typography
              variant="h3"
              gutterBottom
              CodigoCampo={CodigoCampo}
              Nombre={Nombre}
            >
              {Nombre === "Nuevo campo" ? `Título 1` : Nombre}
            </Typography>
          </div>
        </div>
      );

      break;
    case 17:
      component = (
        <div style={{ textAlign: "left" }}>
          <div>
            <strong>Descripción</strong>
          </div>
          <div>
            <Typography
              variant="body1"
              gutterBottom
              CodigoCampo={CodigoCampo}
              Nombre={Nombre}
            >
              {Nombre === "Nuevo campo"
                ? `
      Lorem ipsum
      `
                : Nombre}
            </Typography>
          </div>
        </div>
      );
      break;
    case 18:
      component = (
        <div style={{ textAlign: "left" }}>
          {Nombre === "Nuevo campo" && (
            <div>
              <div>
                <strong>
                  {Nombre === "Nuevo campo" ? "Imagen fija" : Nombre}
                </strong>
              </div>
              <div>
                <Skeleton variant="rect" width={"100%"} height={118} />
              </div>
            </div>
          )}
          {Nombre !== "Nuevo campo" && (
            <div>
              <div
                style={{
                  display: "flex",
                  maxWidth: "100%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={`${folderPath}/${Nombre}`}
                  style={{ maxWidth: "75%", objectFit: "fill" }}
                  alt={""}
                />
              </div>
            </div>
          )}
        </div>
      );
      break;
    case 19:
      component = (
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "left" }}
          >
            <strong>{Nombre}</strong>
            {/* <FormControl style={{ width: "100%" }} disabled>
                <InputLabel htmlFor="idSubEntidad">{Nombre}</InputLabel>
                <Select
                  name={"select" + Nombre}
                  input={
                    <Input name={"select" + Nombre} id={"select" + Nombre} />
                  }
                ></Select>
                <FormHelperText />
              </FormControl> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={true}
                    // onChange={this.handleChange("activo")}
                    value="activo"
                    color="primary"
                  />
                }
                label={"Opt1"}
              />
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={true}
                    // onChange={this.handleChange("activo")}
                    value="activo"
                    color="primary"
                  />
                }
                label={"Opt2"}
              />
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={true}
                    // onChange={this.handleChange("activo")}
                    value="activo"
                    color="primary"
                  />
                }
                label={"Opt3"}
              />
            </div>
          </Grid>
        </Grid>
      );
      break;
    case 20: {
      component = (
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "left" }}
          >
            <strong>{Nombre}</strong>

            <DataGridSample />
          </Grid>
        </Grid>
      );
      break;
    }
    case 21:
      component = (
        <InputCampoCalculado
          CodigoCampo={CodigoCampo}
          Nombre={Nombre}
          Icono={iconType(CodigoTipoCampo, {
            color: "#2196F3",
            marginRight: 10,
          })}
        />
      );
      break;
    case 22:
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <InputLabel htmlFor="idSubEntidad">{Nombre}</InputLabel>
              <Select
                name={"select" + Nombre}
                // inputRef={this.idSubentidadRef}
                // value={this.state.codigoSubentidadCombo}
                // onChange={this.handleChangeGeneral("codigoSubentidadCombo")}
                input={
                  <Input name={"select" + Nombre} id={"select" + Nombre} />
                }
              ></Select>
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;

    case 23:
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <InputLabel htmlFor={"select" + Nombre}>{Nombre}</InputLabel>
              <Select
                name={"select" + Nombre}
                // inputRef={this.idSubentidadRef}
                // value={this.state.codigoSubentidadCombo}
                // onChange={this.handleChangeGeneral("codigoSubentidadCombo")}
                input={
                  <Input name={"select" + Nombre} id={"select" + Nombre} />
                }
              ></Select>
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;
    case 25: //sketch
      component = (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }} disabled>
              <ReactSketchCanvas
                // ref={this.canvas}
                strokeWidth={5}
                strokeColor="black"
              />
              <FormHelperText />
            </FormControl>
          </Grid>
        </Grid>
      );
      break;
    default:
      component = (
        <InputTexto
          CodigoCampo={CodigoCampo}
          Nombre={Nombre}
          Icono={iconType(CodigoTipoCampo, {
            color: "#2196F3",
            marginRight: 10,
          })}
        />
      );
      break;
  }
  return component;
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

// const theme = useTheme();

class FormDesigner extends Component {
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
  }
  state = {
    codigoPlantilla: "",
    nombre: "",
    open: false,
    openToolbar: false,
    actionType: "", //Crear, Actualizar
    items: [],
    deletedItems: [],
    codigoCampo: "",
    nombreCampo: "",
    valorMinimoCampo: "",
    valorMaximoCampo: "",
    requeridoCampo: false,
    mostrarEnDetalle: false,
    llaveCampo: "",
    origenDatosCampo: "",
    codigoCampoPadreCampo: "",
    listaItemsPickList: [],
    listaCamposCampoCalculado: [],
    listaCamposCampoDependiente: [],
    codigoTipoCampo: "",
    valorPorDefecto: "",
    displayTipoNumero: "none",
    displayTipoLista: "none",
    displayTipoSap: "none",
    displayObligatorio: "inline-block",
    displayOpcionesCampoCalculado: "none",
    displayTipoListaAPI: "none",
    confirmTexto: "",
    confirmOpen: false,
    codigoCampoEliminar: "",
    rightDrawer: false,
    key: 0,
    codigoFormDeCampo: null,
    openFormPanel: false,
    openAskNumberModal: false,
    codigoCampoSeccion: 0,
    campoLimite: 0,
    tipoLimite: "",
    mostrarEnMapa: false,
    placeHolderIgualNombre: true,
    placeHolderText: "",
    tipoDato: null,
    listaTipoDato: [],
  };

  scrollToElement = () => {
    const { current } = this.bottomRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };

  iterarKey = () => {
    let newKey = this.state.newKey + 1;
    this.setState({ key: newKey });
  };

  uploadFiles = async (files) => {
    console.log("Uploading...");
    const session = await isSignedIn();

    if (files.length > 0) {
      let data = new FormData();
      data.append("CodigoCampo", this.state.codigoCampo);
      data.append("UsuarioInserto", session["userName"]);

      await files.forEach(async (element2, j) => {
        await data.append("files[]", element2);
      });

      let request = await API.post("/api/campo/agregarMultimedia", data, {
        "content-type": "multipart/form-data",
      });
      await console.log(request);
      await this.cargarListaCampos(true);
      this.iterarKey();
      this.limpiarEdicionCampos();
      this.setState({ rightDrawer: false });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMoveControls(items, oldIndex, newIndex),
      }),
      () => {
        this.cambiarOrden();
      }
    );
  };

  handleDrawerOpen = () => {
    this.setState({ openToolbar: true });
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  handleAddChip = async (nombreItem) => {
    let vIsSignedIn = await isSignedIn();
    const request = await API.post(
      `api/campo/crearItemList/${this.state.codigoCampo}/${nombreItem}/${vIsSignedIn.userName}`
    );
    this.cargarPickListItems();
  };

  handleDeleteChip = async (nombreItem) => {
    let vIsSignedIn = await isSignedIn();
    const request = await API.post(
      `api/campo/eliminarItemList/${this.state.codigoCampo}/${nombreItem}/${vIsSignedIn.userName}`
    );
    this.cargarPickListItems();
  };

  cargarPickListItems = async () => {
    const request = await API.fetchGetRequest(
      `api/campo/listaItemsPickList/${this.state.codigoCampo}`
    );

    this.setState({
      listaItemsPickList: request.data.data,
    });
  };

  cargarPickListItemsAPI = async () => {
    const request = await API.fetchGetRequest(
      `api/campo/listaItemsPickList/${this.state.codigoCampo}`
    );

    this.setState({
      listaItemsPickList: request.data.data,
    });
  };

  cargarTipoDato = async () => {
    const request = await API.fetchGetRequest(`api/campo/getTipoDato/`);

    this.setState({
      listaTipoDato: request.data.data,
    });
  };

  async componentDidMount() {
    //Cargar lista subentidades
    if (
      this.props.codigoPlantilla !== null &&
      this.props.codigoPlantilla !== undefined
    ) {
      this.setState(
        { codigoPlantilla: parseInt(this.props.codigoPlantilla) },
        async () => {
          await this.cargarDatosEdicionForm();
          //Activos
          this.cargarListaCampos(true);
          //borrados
          this.cargarListaCampos(false);
        }
      );
      this.cargarTipoDato();
    }
  }

  componentDidUpdate() {
    if (
      this.props.codigoPlantilla !== null &&
      this.props.codigoPlantilla !== undefined &&
      this.props.codigoPlantilla !== "" &&
      this.props.codigoPlantilla !== this.state.codigoPlantilla
    ) {
      console.log("Props.CodigoPlantilla", this.props.codigoPlantilla);
      console.log("state.CodigoPlantilla", this.state.codigoPlantilla);
      this.setState(
        { codigoPlantilla: parseInt(this.props.codigoPlantilla) },
        async () => {
          await this.cargarDatosEdicionForm();
          //Activos
          this.cargarListaCampos(true);
          //borrados
          this.cargarListaCampos(false);
        }
      );
    }
  }

  async cargarListaCampos(activos) {
    const request = await API.fetchGetRequest(
      `api/campo/listarCampos/${this.state.codigoPlantilla}/${activos}`
    );

    if (activos === true) {
      this.setState({
        items: request.data.data,
      });
    } else {
      this.setState({
        deletedItems: request.data.data,
      });
    }
  }

  async cargarDatosEdicionForm() {
    const request = await API.fetchGetRequest(
      "api/form/cargarDatosEdicion/" + this.state.codigoPlantilla
    );
    if (request.data !== null) {
      this.setState(
        {
          nombre: request.data.data.Nombre,
        },

        () => {
          //Datos de plantilla y tareas
        }
      );
    }
  }

  nuevoCampo = async (tipoCampo) => {
    let vIsSignedIn = await isSignedIn();
    let object = {
      CodigoCampo: null,
      CodigoPlantilla: this.state.codigoPlantilla,
      CodigoTipoCampo: tipoCampo,
      Nombre: "Nuevo campo",
      Descripcion: null,
      Llave: null,
      Requerido: false,
      Orden: 0,
      ValorMinimo: null,
      ValorMaximo: null,
      Activo: true,
      Eliminado: false,
      UsuarioOpera: vIsSignedIn.userName,
      OrigenDatos: null,
      CodigoCampoPadre: null,
      DependeDe: null,
      PlaceHolderIgualNombre: true,
      PlaceHolderText: "",
    };

    let request = await API.post(`api/campo/grabarEditar`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      toast.success("El campo se creó exitosamente.");
      this.cargarListaCampos(true);
      document.getElementById("bottom").scrollIntoView();
      this.scrollToElement();
    }
  };

  cambiarOrden = async () => {
    let vIsSignedIn = await isSignedIn();
    let object = {
      campos: this.state.items,
      usuarioOpera: vIsSignedIn.userName,
    };
    console.log(object);
    let request = await API.post(`api/campo/cambiarOrden`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      //Activos
      this.cargarListaCampos(true);
    }
  };

  //Esta función carga los inputs que se muestran en el DRAWER de la configuración de un campo...
  cargarDatosCampo = async (codigoCampo) => {
    const request = await API.fetchGetRequest(
      `api/campo/obtenerPorId/${codigoCampo}`
    );

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      this.setState({
        codigoCampo: request.data.data.CodigoCampo,
        nombreCampo: request.data.data.Nombre,
        descripcionCampo: request.data.data.Descipcion,
        valorMinimoCampo: request.data.data.ValorMinimo,
        valorMaximoCampo: request.data.data.ValorMaximo,
        requeridoCampo: request.data.data.Requerido,
        mostrarEnDetalle: request.data.data.mostrarEnDetalle,
        llaveCampo: request.data.data.Llave,
        origenDatosCampo: request.data.data.OrigenDatos,
        codigoCampoPadreCampo: request.data.data.CodigoCampoPadre,
        codigoTipoCampo: request.data.data.CodigoTipoCampo,
        valorPorDefecto: request.data.data.ValorPorDefecto,
        dependeDe: request.data.data.DependeDe,
        campoLimite: request.data.data.campoLimite,
        tipoLimite: request.data.data.tipoLimite,
        tipoDato: request.data.data.CodigoTipoDato,
        codigoFormDeCampo:
          request.data.data.CodigoTipoCampo === 20
            ? request.data.data.ValorPorDefecto
            : null, //Si es campo tipo grid
        mostrarEnMapa: request.data.data.MostrarEnMapa,
        placeHolderIgualNombre: request.data.data.PlaceHolderIgualNombre,
        placeHolderText: request.data.data.PlaceHolderText,
        rightDrawer: true,
      });

      //Para todos los casos, obtiene el listado de campos disponibles si queremos hacer del campo un campo dependiente
      this.obtenerCamposDisponiblesParaCampoDependiente();

      //Oculta y muestra controles según tipo de campo
      switch (request.data.data.CodigoTipoCampo) {
        case 2:
          this.setState({
            displayTipoNumero: "inline-block",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "none",
          });
          break;
        case 5:
        case 19:
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "inline-block",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "none",
          });
          this.cargarPickListItems();
          break;
        case 10:
        case 12:
        case 13:
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "inline-block",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "none",
          });
          break;
        case 21: //Campo Calculado
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "none",
            displayOpcionesCampoCalculado: "inline-block",
          });
          this.obtenerCamposDisponiblesParaCampoCalculado();
          break;
        case 22: //URI List
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "inline-block",
          });
          this.cargarPickListItemsAPI();
          break;
        case (22, 23): //URI List
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "inline-block",
          });
          this.cargarPickListItemsAPI();
          break;
        case 25: //Sketch
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "none",
          });
          break;
        default:
          this.setState({
            displayTipoNumero: "none",
            displayTipoLista: "none",
            displayTipoSap: "none",
            displayObligatorio: "inline-block",
            displayOpcionesCampoCalculado: "none",
            displayTipoListaAPI: "none",
          });
          break;
      }

      // this.toggleConfigurationDrawer('rightDrawer', true)
    }
  };

  editarCampo = async () => {
    // El día 10/03/2020, bebí caldo de frutas traído desde Salcajá, lo que significa que mientras escribía este método estaba ebrio,
    // pero aún consciente de mis actos.
    // La verdad preferí alejarme de los jóvenes perdidos que me estaban ofreciendo alcohol, porque querían que los invitara a una ronda de cervezas
    // y la verdad así ya no es divertido beber. Como siempre he dicho "Beber es chilero mientras sea gratis."
    // Bendiciones.
    let vIsSignedIn = await isSignedIn();
    let object = {
      CodigoCampo: this.state.codigoCampo,
      CodigoPlantilla: this.state.codigoPlantilla,
      CodigoTipoCampo: this.state.codigoTipoCampo,
      Nombre: this.state.nombreCampo,
      Descripcion: this.state.descripcionCampo,
      Llave: this.state.llaveCampo === "" ? null : this.state.llaveCampo,
      Requerido: this.state.requeridoCampo,
      mostrarEnDetalle: this.state.mostrarEnDetalle,
      Orden: 0,
      ValorMinimo:
        this.state.valorMinimoCampo === "" ? null : this.state.valorMinimoCampo,
      ValorMaximo:
        this.state.valorMaximoCampo === "" ? null : this.state.valorMaximoCampo,
      Activo: true,
      Eliminado: false,
      UsuarioOpera: vIsSignedIn.userName,
      OrigenDatos: this.state.origenDatosCampo,
      CodigoCampoPadre:
        this.state.codigoCampoPadreCampo === ""
          ? null
          : this.state.codigoCampoPadreCampo,
      ValorPorDefecto: this.state.valorPorDefecto,
      DependeDe: this.state.dependeDe,
      campoLimite: this.state.campoLimite,
      tipoLimite: this.state.tipoLimite,
      mostrarEnMapa: this.state.mostrarEnMapa,
      PlaceHolderIgualNombre: this.state.placeHolderIgualNombre,
      PlaceHolderText: this.state.placeHolderText,
      CodigoTipoDato: this.state.tipoDato,
    };

    console.log("Depende DE", this.state.dependeDe);

    let request = await API.post(`api/campo/grabarEditar`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      toast.success("El campo se editó exitosamente.");
      this.cargarListaCampos(true);
      this.limpiarEdicionCampos();
      this.setState({ rightDrawer: false });
    }
  };

  handleChange = (name) => (event) => {
    console.log("NAME ON CHANGE", name);
    console.log("EVENT ON CHANGE", event.target.value);
    if (
      name === "activo" ||
      name === "requeridoCampo" ||
      name === "mostrarEnDetalle" ||
      name === "mostrarEnMapa" ||
      name === "placeHolderIgualNombre"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  limpiarEdicionCampos = () => {
    this.setState({
      codigoCampo: "",
      nombreCampo: "",
      valorPorDefecto: "",
      descripcionCampo: "",
      valorMinimoCampo: "",
      valorMaximoCampo: "",
      requeridoCampo: false,
      mostrarEnDetalle: false,
      llaveCampo: "",
      origenDatosCampo: "",
      codigoCampoPadreCampo: "",
      codigoTipoCampo: "",
      listaItemsPickList: [],
      placeHolderIgualNombre: true,
      placeHolderText: "",
      tipoDato: null,
    });
  };

  confirmarAccion = (codigoCampo) => {
    this.setState({
      confirmTexto: `¿Confirma que desea eliminar este campo? esta acción no podrá revertirse.`,
      confirmOpen: true,
      codigoCampoEliminar: codigoCampo,
    });
  };

  funcionOk = () => {
    this.eliminarCampo();
  };

  cerrarConfirm = () => {
    this.setState({ confirmTexto: "", confirmOpen: false });
  };

  eliminarCampo = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/campo/eliminar/${this.state.codigoCampoEliminar}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El campo se eliminó exitosamente.");
          //Activos
          this.cargarListaCampos(true);
          //borrados
          this.cargarListaCampos(false);
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoCampoEliminar: "" });
      this.limpiarEdicionCampos(true);
    }
  };

  restaurarCampo = async (codigoCampo) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/campo/restaurar/${codigoCampo}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El campo se restauró exitosamente.");
          //Activos
          this.cargarListaCampos(true);
          //borrados
          this.cargarListaCampos(false);
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoCampoEliminar: "" });
      this.limpiarEdicionCampos(true);
    }
  };

  handleOpenFormPanel = () => {
    this.setState({
      openFormPanel: true,
    });
  };

  handleCloseFormPanel = () => {
    this.setState({
      openFormPanel: false,
    });
  };

  //*************************************************************** */
  //Funciones de CAMPO CALCULADO **********************************
  //*************************************************************** */
  obtenerCamposDisponiblesParaCampoCalculado = async () => {
    const request = await API.fetchGetRequest(
      `api/campo/camposParaCampoCalculado/${this.state.codigoCampo}`
    );

    this.setState({
      listaCamposCampoCalculado: request.data.data,
    });
  };

  //*************************************************************** */
  //Funciones de CAMPO DEPENDIENTE **********************************
  //*************************************************************** */
  obtenerCamposDisponiblesParaCampoDependiente = async () => {
    const request = await API.fetchGetRequest(
      `api/campo/camposParaCampoDependiente/${this.state.codigoCampo}`
    );

    console.log(
      "LISTA DE CAMPOS DISPONIBLES PARA CAMPO DEPENDIENTE",
      request.data.data
    );
    this.setState({
      listaCamposCampoDependiente: request.data.data,
    });
  };

  handleAskNumberModal = (codigoCampo) => {
    console.log("VALUE", codigoCampo);
    this.setState({
      openAskNumberModal: true,
      codigoCampoSeccion: codigoCampo,
    });
  };
  handleAskNumberModalClose = () => {
    this.setState({
      openAskNumberModal: false,
    });
  };
  handleSaveSeccion = async (value) => {
    let vIsSignedIn = await isSignedIn();
    let object = {
      CodigoCampo: this.state.codigoCampoSeccion,
      Seccion: value,
    };

    let request = await API.post(`api/campo/grabarEditarSeccion`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      toast.success("Se actualizó correctamente.");
      this.cargarListaCampos(true);
      //document.getElementById("bottom").scrollIntoView();
    }
    this.handleAskNumberModalClose();
  };

  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <React.Fragment>
        <Container maxWidth="md">
          <Paper className={classes.rootTab}>
            <AppBar
              position="static"
              color="default"
              style={{ width: "100% !important" }}
            ></AppBar>
            <div
              style={{ width: "100% !important" }}
              // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              // index={this.state.valorTab}
              // onChangeIndex={this.handleChangeIndexTabs}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />
                    {/* <AppBar
                      position="sticky"
                      className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.openToolbar,
                      })}
                      style={{ backgroundColor: titleColor }}
                    >
                      <Toolbar>
                        <Typography variant="h6" noWrap>
                          {`Formulario ${this.state.nombre}`}
                        </Typography>
                      </Toolbar>
                    </AppBar> */}
                    <Drawer
                      className={classes.drawer}
                      variant="temporary"
                      anchor="left"
                      open={this.state.openToolbar}
                      onClose={this.toggleConfigurationDrawer(
                        "openToolbar",
                        false
                      )}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <div
                        className={classes.drawerHeader}
                        style={{ backgroundColor: titleColor, color: "#fff" }}
                      >
                        <IconButton
                          // onClick={this.handleDrawerClose}
                          onClick={this.toggleConfigurationDrawer(
                            "openToolbar",
                            false
                          )}
                        >
                          {this.state.openToolbar === true ? (
                            <CloseIcon style={{ color: "#fff" }} />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </IconButton>
                        <strong>Campos</strong>
                      </div>
                      <Divider />
                      <List>
                        <ListItem
                          button
                          key={1}
                          onClick={() => {
                            this.nuevoCampo(1);
                          }}
                        >
                          <ListItemIcon>
                            <InputIcon />
                          </ListItemIcon>
                          <ListItemText primary="Entrada de texto" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={2}
                          onClick={() => {
                            this.nuevoCampo(2);
                          }}
                        >
                          <ListItemIcon>
                            <Filter1Icon />
                          </ListItemIcon>
                          <ListItemText primary="Número" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={3}
                          onClick={() => {
                            this.nuevoCampo(3);
                          }}
                        >
                          <ListItemIcon>
                            <TodayIcon />
                          </ListItemIcon>
                          <ListItemText primary="Fecha" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={4}
                          onClick={() => {
                            this.nuevoCampo(4);
                          }}
                        >
                          <ListItemIcon>
                            <ViewHeadlineIcon />
                          </ListItemIcon>
                          <ListItemText primary="Párrafo" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={5}
                          onClick={() => {
                            this.nuevoCampo(5);
                          }}
                        >
                          <ListItemIcon>
                            <ArrowDropDownCircleIcon />
                          </ListItemIcon>
                          <ListItemText primary="Dropdown" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={6}
                          onClick={() => {
                            this.nuevoCampo(6);
                          }}
                        >
                          <ListItemIcon>
                            <ImageIcon />
                          </ListItemIcon>
                          <ListItemText primary="Imagen" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={7}
                          onClick={() => {
                            this.nuevoCampo(7);
                          }}
                        >
                          <ListItemIcon>
                            <CheckBoxIcon />
                          </ListItemIcon>
                          <ListItemText primary="Checkbox" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={8}
                          onClick={() => {
                            this.nuevoCampo(8);
                          }}
                        >
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          <ListItemText primary="Adjunto" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={14}
                          onClick={() => {
                            this.nuevoCampo(14);
                          }}
                        >
                          <ListItemIcon>
                            <AccessTimeIcon />
                          </ListItemIcon>
                          <ListItemText primary="Hora (24 hrs)" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={14}
                          onClick={() => {
                            this.nuevoCampo(15);
                          }}
                        >
                          <ListItemIcon>
                            <DehazeIcon />
                          </ListItemIcon>
                          <ListItemText primary="Separador" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={10}
                          onClick={() => {
                            this.nuevoCampo(10);
                          }}
                        >
                          <ListItemIcon>
                            <ArrowDropDownCircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Picklist SAP" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={12}
                          onClick={() => {
                            this.nuevoCampo(12);
                          }}
                        >
                          <ListItemIcon>
                            <ArrowDropDownCircleTwoToneIcon />
                          </ListItemIcon>
                          <ListItemText primary="Picklist SAP Tree" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={13}
                          onClick={() => {
                            this.nuevoCampo(13);
                          }}
                        >
                          <ListItemIcon>
                            <MessageIcon />
                          </ListItemIcon>
                          <ListItemText primary="Texto SAP extendido" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={16}
                          onClick={() => {
                            this.nuevoCampo(16);
                          }}
                        >
                          <ListItemIcon>
                            <TitleIcon />
                          </ListItemIcon>
                          <ListItemText primary="Título" />
                          <AddIcon />
                        </ListItem>

                        <ListItem
                          button
                          key={17}
                          onClick={() => {
                            this.nuevoCampo(17);
                          }}
                        >
                          <ListItemIcon>
                            <DescriptionIcon />
                          </ListItemIcon>
                          <ListItemText primary="Descripción" />
                          <AddIcon />
                        </ListItem>

                        <ListItem
                          button
                          key={18}
                          onClick={() => {
                            this.nuevoCampo(18);
                          }}
                        >
                          <ListItemIcon>
                            <WallpaperIcon />
                          </ListItemIcon>
                          <ListItemText primary="Imagen fija" />
                          <AddIcon />
                        </ListItem>

                        <ListItem
                          button
                          key={19}
                          onClick={() => {
                            this.nuevoCampo(19);
                          }}
                        >
                          <ListItemIcon>
                            <LibraryAddCheckIcon />
                          </ListItemIcon>
                          <ListItemText primary="Checklist" />
                          <AddIcon />
                        </ListItem>

                        <ListItem
                          button
                          key={20}
                          onClick={() => {
                            this.nuevoCampo(20);
                          }}
                        >
                          <ListItemIcon>
                            <GridOnIcon />
                          </ListItemIcon>
                          <ListItemText primary="Grid" />
                          <AddIcon />
                        </ListItem>

                        <ListItem
                          button
                          key={21}
                          onClick={() => {
                            this.nuevoCampo(21);
                          }}
                        >
                          <ListItemIcon>
                            <FunctionsIcon />
                          </ListItemIcon>
                          <ListItemText primary="Campo calculado" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={22}
                          onClick={() => {
                            this.nuevoCampo(22);
                          }}
                        >
                          <ListItemIcon>
                            <ArrowDropDownCircleIcon />
                          </ListItemIcon>
                          <ListItemText primary="Dropdown API" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={23}
                          onClick={() => {
                            this.nuevoCampo(23);
                          }}
                        >
                          <ListItemIcon>
                            <ArrowDropDownCircleIcon />
                          </ListItemIcon>
                          <ListItemText primary="API MCU" />
                          <AddIcon />
                        </ListItem>
                        <ListItem
                          button
                          key={25}
                          onClick={() => {
                            this.nuevoCampo(25);
                          }}
                        >
                          <ListItemIcon>
                            <GestureIcon />
                          </ListItemIcon>
                          <ListItemText primary="Sketch" />
                          <AddIcon />
                        </ListItem>
                      </List>
                      <Divider />
                      <div
                        className={classes.drawerHeader}
                        style={{ backgroundColor: titleColor, color: "#fff" }}
                      >
                        <strong>Campos inactivos</strong>
                      </div>
                      <List>
                        {this.state.deletedItems.map((row, index) => (
                          <ListItem
                            button
                            key={`Campo${row.CodigoCampo}`}
                            onClick={() => {
                              this.restaurarCampo(row.CodigoCampo);
                            }}
                          >
                            <ListItemIcon>
                              {iconType(row.CodigoTipoCampo)}
                            </ListItemIcon>
                            <ListItemText primary={row.Nombre} />
                            <AddIcon />
                          </ListItem>
                        ))}
                      </List>
                    </Drawer>
                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        <SortableContainer
                          pressDelay={200}
                          onSortEnd={this.onSortEnd}
                        >
                          {items.map((value, index) => (
                            <SortableItemObject
                              key={`item-${value}`}
                              index={index}
                              value={value}
                              clickFunction={this.cargarDatosCampo}
                              confirmarEliminar={this.confirmarAccion}
                              clickSeccion={this.handleAskNumberModal}
                              seccion={value.Seccion}
                            />
                          ))}
                        </SortableContainer>
                      </Grid>
                      <Grid item xs={12} lg={12} sm={12}>
                        <div
                          className="dragCard"
                          data-codigocampo={"add"}
                          zIndex="1"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Add
                            style={{ fontSize: 100, color: "#e0e0e0" }}
                            onClick={this.handleDrawerOpen}
                          />
                        </div>
                      </Grid>
                    </main>

                    <Drawer
                      anchor="right"
                      open={this.state.rightDrawer}
                      onClose={this.toggleConfigurationDrawer(
                        "rightDrawer",
                        false
                      )}
                      className={classes.drawer}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <AppBar
                        position="sticky"
                        style={{ backgroundColor: titleColor }}
                      >
                        <Toolbar>
                          <Typography variant="h6">
                            Configuración del campo &nbsp;&nbsp;
                          </Typography>
                          <IconButton
                            color="inherit"
                            aria-label="Cerrar"
                            onClick={this.toggleConfigurationDrawer(
                              "rightDrawer",
                              false
                            )}
                          >
                            <CloseIcon style={{ right: 0 }} />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={10}
                            lg={10}
                            sm={10}
                            style={{ margin: "auto", paddingTop: 15 }}
                          >
                            <form
                              className={classes.container}
                              noValidate
                              autoComplete="off"
                            >
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ paddingTop: 30 }}
                              >
                                <TextField
                                  disabled
                                  id="txtCodigoCampo"
                                  label="ID Campo"
                                  className={classes.textField}
                                  value={this.state.codigoCampo}
                                  onChange={this.handleChange("codigoCampo")}
                                  margin="normal"
                                  inputProps={{ maxLength: 512 }}
                                />
                              </Grid>

                              <Grid item xs={12} lg={12} sm={12}>
                                {this.state.codigoTipoCampo !== 17 &&
                                  this.state.codigoTipoCampo !== 18 && (
                                    <TextField
                                      id="txtNombreCampo"
                                      label="Título del campo"
                                      className={classes.textField}
                                      value={this.state.nombreCampo}
                                      onChange={this.handleChange(
                                        "nombreCampo"
                                      )}
                                      margin="normal"
                                      inputProps={{ maxLength: 512 }}
                                    />
                                  )}
                                {this.state.codigoTipoCampo === 17 && (
                                  <TextField
                                    multiline
                                    rows={2}
                                    id="txtNombreCampo"
                                    label="Escribe la descripción"
                                    className={classes.textField}
                                    value={this.state.nombreCampo}
                                    onChange={this.handleChange("nombreCampo")}
                                    margin="normal"
                                    inputProps={{ maxLength: 512 }}
                                  />
                                )}

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  style={{
                                    display: this.state.displayObligatorio,
                                  }}
                                >
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            this.state.placeHolderIgualNombre
                                          }
                                          onChange={this.handleChange(
                                            "placeHolderIgualNombre"
                                          )}
                                          value="placeHolderIgualNombre"
                                          color="primary"
                                        />
                                      }
                                      label="Placeholder igual al título"
                                    />
                                    <br />
                                  </div>
                                </Grid>

                                {!this.state.placeHolderIgualNombre && (
                                  <Grid item xs={12} lg={12} sm={12}>
                                    <TextField
                                      multiline
                                      rows={2}
                                      id="txtPlaceHolderText"
                                      label="Placeholder"
                                      className={classes.textField}
                                      value={this.state.placeHolderText}
                                      onChange={this.handleChange(
                                        "placeHolderText"
                                      )}
                                      margin="normal"
                                      inputProps={{ maxLength: 512 }}
                                    />
                                  </Grid>
                                )}

                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  sm={12}
                                  container
                                  spacing={1}
                                  className={classes.textField}
                                  // style={{
                                  //   display: this.state.displayTipoNumero,
                                  // }}
                                >
                                  <FormLabel
                                    component="legend"
                                    style={{
                                      marginTop: "25px",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    Tipo de dato:
                                  </FormLabel>

                                  <Grid item xs={12} lg={12} sm={12}>
                                    <FormControl
                                      className={clsx(
                                        classes.margin,
                                        classes.withoutLabel,
                                        classes.textField
                                      )}
                                    >
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.tipoDato || ""}
                                        onChange={this.handleChange("tipoDato")}
                                      >
                                        {this.state.listaTipoDato.map(
                                          (row, index) => (
                                            <MenuItem
                                              key={index}
                                              value={row.CodigoTipoDato}
                                            >
                                              {row.Nombre}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/* Se muestra la opción de valor por defecto para campos de texto y numérico; Se agrego tipo Parrafo */}
                                {(this.state.codigoTipoCampo === 1 ||
                                  this.state.codigoTipoCampo === 2 ||
                                  this.state.codigoTipoCampo === 4) && (
                                  <TextField
                                    multiline
                                    rows={2}
                                    id="txtValorPorDefecto"
                                    label="Valor por defecto"
                                    className={classes.textField}
                                    value={this.state.valorPorDefecto}
                                    onChange={this.handleChange(
                                      "valorPorDefecto"
                                    )}
                                    margin="normal"
                                    inputProps={{ maxLength: 512 }}
                                  />
                                )}

                                {this.state.codigoTipoCampo === 18 && (
                                  <DropzoneArea
                                    dropzoneText="Selecciona una imagen"
                                    onChange={this.uploadFiles.bind(this)}
                                    acceptedFiles={[
                                      "image/jpeg",
                                      "image/png",
                                      "image/bmp",
                                    ]}
                                    maxFileSize={1073741824}
                                    filesLimit={1}
                                    key={
                                      this.state.key + this.state.codigoCampo
                                    }
                                  />
                                )}
                              </Grid>

                              {/* Mostramos las opciones de RANGOS para los campos de tipo NUMERICO */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                container
                                spacing={1}
                                style={{
                                  display: this.state.displayTipoNumero,
                                }}
                              >
                                <FormLabel
                                  component="legend"
                                  style={{
                                    marginTop: "25px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  Rango:
                                </FormLabel>
                                <FormGroup aria-label="position" row>
                                  <Grid item xs={12} lg={6} sm={12}>
                                    <FormControl
                                      className={clsx(
                                        classes.margin,
                                        classes.withoutLabel,
                                        classes.textField
                                      )}
                                    >
                                      <Input
                                        type="number"
                                        id="txtValorMinimoCampo"
                                        value={this.state.valorMinimoCampo}
                                        onChange={this.handleChange(
                                          "valorMinimoCampo"
                                        )}
                                        endAdornment={
                                          <InputAdornment position="top"></InputAdornment>
                                        }
                                        aria-describedby="Valor mínimo"
                                        inputProps={{
                                          "aria-label": "Min",
                                        }}
                                      />
                                      <FormHelperText id="Valor mínimo">
                                        Min
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} lg={6} sm={12}>
                                    <FormControl
                                      className={clsx(
                                        classes.margin,
                                        classes.withoutLabel,
                                        classes.textField
                                      )}
                                    >
                                      <Input
                                        type="number"
                                        id="txtValorMaximoCampo"
                                        value={this.state.valorMaximoCampo}
                                        onChange={this.handleChange(
                                          "valorMaximoCampo"
                                        )}
                                        endAdornment={
                                          <InputAdornment position="top"></InputAdornment>
                                        }
                                        aria-describedby="Valor máximo"
                                        inputProps={{
                                          "aria-label": "Max",
                                        }}
                                      />
                                      <FormHelperText id="Valor máximo">
                                        Max
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </FormGroup>
                              </Grid>

                              {/* Se muestra la opción de añadir límite inferior o superior a un campo de tipo numérico */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                container
                                spacing={1}
                                style={{
                                  display: this.state.displayTipoNumero,
                                }}
                              >
                                <FormLabel
                                  component="legend"
                                  style={{
                                    marginTop: "25px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  Campo límite:
                                </FormLabel>
                                <FormGroup aria-label="position" row>
                                  <Grid item xs={12} lg={12} sm={12}>
                                    <FormControl
                                      className={clsx(
                                        classes.margin,
                                        classes.withoutLabel,
                                        classes.textField
                                      )}
                                    >
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.campoLimite || ""}
                                        onChange={this.handleChange(
                                          "campoLimite"
                                        )}
                                      >
                                        {this.state.listaCamposCampoDependiente
                                          .filter(
                                            (x) => x.CodigoTipoCampo === 2
                                          )
                                          .map((row, index) => (
                                            <MenuItem
                                              key={index}
                                              value={row.CodigoCampo}
                                            >
                                              {row.Nombre}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.campoLimite &&
                                      this.state.campoLimite > 0 && (
                                        <>
                                          <FormLabel
                                            component="legend"
                                            style={{
                                              marginTop: "25px",
                                              marginLeft: "8px",
                                            }}
                                          >
                                            Tipo de límite:
                                          </FormLabel>

                                          <FormControl
                                            className={clsx(
                                              classes.margin,
                                              classes.withoutLabel,
                                              classes.textField
                                            )}
                                          >
                                            <Select
                                              labelId="demo-simple-select-label2"
                                              id="demo-simple-select2"
                                              value={
                                                this.state.tipoLimite || ""
                                              }
                                              onChange={this.handleChange(
                                                "tipoLimite"
                                              )}
                                            >
                                              <MenuItem value={"min"}>
                                                Mínimo
                                              </MenuItem>
                                              <MenuItem value={"max"}>
                                                Máximo
                                              </MenuItem>
                                            </Select>
                                            <FormHelperText id="Valor mínimo">
                                              Tipo
                                            </FormHelperText>
                                          </FormControl>
                                        </>
                                      )}
                                  </Grid>
                                </FormGroup>
                              </Grid>

                              {/* Mostramos la opción de OBLIGATORIO */}
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{
                                  display: this.state.displayObligatorio,
                                }}
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.requeridoCampo}
                                        onChange={this.handleChange(
                                          "requeridoCampo"
                                        )}
                                        value="Obligatorio"
                                        color="primary"
                                      />
                                    }
                                    label="Obligatorio"
                                  />
                                  <br />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{
                                  display: this.state.displayObligatorio,
                                }}
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.mostrarEnDetalle}
                                        onChange={this.handleChange(
                                          "mostrarEnDetalle"
                                        )}
                                        value="mostrarEnDetalle"
                                        color="primary"
                                      />
                                    }
                                    label="Mostrar en detalle"
                                  />
                                  <br />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{
                                  display: this.state.displayObligatorio,
                                }}
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.mostrarEnMapa}
                                        onChange={this.handleChange(
                                          "mostrarEnMapa"
                                        )}
                                        value="mostrarEnMapa"
                                        color="primary"
                                      />
                                    }
                                    label="Mostrar en mapa"
                                  />
                                  <br />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ display: this.state.displayTipoSap }}
                              >
                                <TextField
                                  id="txtLlaveCampo"
                                  label="Llave"
                                  className={classes.textField}
                                  value={this.state.llaveCampo}
                                  onChange={this.handleChange("llaveCampo")}
                                  margin="normal"
                                  inputProps={{ maxLength: 50 }}
                                />
                              </Grid>

                              {/* Mostramos la opción de ORIGEN DE DATOS (Esto es para los campos de SAP) */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ display: this.state.displayTipoSap }}
                              >
                                <TextField
                                  id="txtOrigenDatosCampo"
                                  label="Origen de datos"
                                  className={classes.textField}
                                  value={this.state.origenDatosCampo}
                                  onChange={this.handleChange(
                                    "origenDatosCampo"
                                  )}
                                  margin="normal"
                                  inputProps={{ maxLength: 150 }}
                                />
                              </Grid>

                              {/* Mostramos la opción de CODIGO CAMPO PADRE (Esto lo utiliza los campos de SAP) */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ display: this.state.displayTipoSap }}
                              >
                                <TextField
                                  type="number"
                                  id="txtCodigoCampoPadre"
                                  label="Cod. Campo padre"
                                  className={classes.textField}
                                  value={this.state.codigoCampoPadreCampo}
                                  onChange={this.handleChange(
                                    "codigoCampoPadreCampo"
                                  )}
                                  margin="normal"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{
                                  display: this.state.displayTipoListaAPI,
                                }}
                              >
                                <TextField
                                  type="number"
                                  id="txtCodigoCampoPadre"
                                  label="Cod. Campo padre"
                                  className={classes.textField}
                                  value={this.state.codigoCampoPadreCampo}
                                  onChange={this.handleChange(
                                    "codigoCampoPadreCampo"
                                  )}
                                  margin="normal"
                                />
                              </Grid>

                              {/* Mostramos la opción de URI. MTohom*/}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{
                                  display: this.state.displayTipoListaAPI,
                                }}
                              >
                                <TextField
                                  id="txtURI"
                                  label="URI"
                                  className={classes.textField}
                                  value={this.state.origenDatosCampo}
                                  onChange={this.handleChange(
                                    "origenDatosCampo"
                                  )}
                                  margin="normal"
                                  inputProps={{ maxLength: 150 }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ display: this.state.displayTipoLista }}
                              >
                                <ChipInput
                                  value={this.state.listaItemsPickList}
                                  onAdd={(chip) => this.handleAddChip(chip)}
                                  onDelete={(chip, index) =>
                                    this.handleDeleteChip(chip, index)
                                  }
                                  label="Ítems"
                                />
                              </Grid>
                              {this.state.codigoTipoCampo === 20 && (
                                <Grid item xs={12} lg={12} sm={12}>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#3498db",
                                      color: "white",
                                      width: "100%",
                                    }}
                                    className={classes.buttonAdd}
                                    onClick={() => this.handleOpenFormPanel()}
                                  >
                                    Columnas...
                                  </Button>
                                </Grid>
                              )}

                              {/* Esta opciones salen cuando es un campo de tipo CAMPO CALCULADO */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                container
                                spacing={1}
                                style={{
                                  display:
                                    this.state.displayOpcionesCampoCalculado,
                                }}
                              >
                                <FormLabel
                                  component="legend"
                                  style={{
                                    width: "100%",
                                    marginTop: "25px",
                                  }}
                                >
                                  Campos Disponibles:
                                </FormLabel>
                                <FormControl className={classes.flexRow}>
                                  <Select
                                    native
                                    inputProps={{
                                      id:
                                        "select-campo-calculado" +
                                        (this.props.esGrid === true
                                          ? "-grid"
                                          : ""),
                                    }}
                                    style={{ flex: 1 }}
                                  >
                                    {this.state.listaCamposCampoCalculado.map(
                                      (campo) => (
                                        <option
                                          key={campo.CodigoCampo}
                                          value={campo.Llave}
                                        >
                                          {campo.Nombre + " - " + campo.Llave}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                    size="small"
                                    onClick={() => {
                                      var sel = document.getElementById(
                                        "select-campo-calculado" +
                                          (this.props.esGrid === true
                                            ? "-grid"
                                            : "")
                                      );
                                      var value =
                                        sel.options[sel.selectedIndex].value;
                                      const { valorPorDefecto } = this.state;

                                      this.setState({
                                        valorPorDefecto:
                                          valorPorDefecto === null
                                            ? value
                                            : valorPorDefecto + "" + value,
                                      });
                                    }}
                                  >
                                    <AddIcon fontSize="inherit" />
                                  </IconButton>
                                </FormControl>
                                <TextField
                                  multiline
                                  rows={5}
                                  id="txtValorPorDefecto"
                                  label="Escribe la fórmula"
                                  style={{ width: "100%" }}
                                  value={this.state.valorPorDefecto}
                                  onChange={this.handleChange(
                                    "valorPorDefecto"
                                  )}
                                  margin="normal"
                                />
                              </Grid>

                              {/* Esta opción sale siempre, para hacer un CAMPO DEPENDIENTE */}
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                container
                                spacing={1}
                                style={{
                                  display: true,
                                }}
                              >
                                <FormLabel
                                  component="legend"
                                  style={{
                                    width: "100%",
                                    marginTop: "25px",
                                  }}
                                >
                                  Campo condicional, depende de:
                                </FormLabel>
                                <FormControl className={classes.flexRow}>
                                  <Select
                                    native
                                    inputProps={{
                                      id: "select-campo-dependiente",
                                    }}
                                    style={{ flex: 1 }}
                                    onChange={(e) =>
                                      this.setState({
                                        dependeDe: e.target.value,
                                      })
                                    }
                                    value={this.state.dependeDe || 0}
                                  >
                                    <option value="0">
                                      Seleccione un campo
                                    </option>

                                    {this.state.listaCamposCampoDependiente.map(
                                      (campo) => {
                                        if (campo.CodigoTipoCampo === 5) {
                                          return (
                                            <optgroup
                                              label={
                                                campo.Nombre +
                                                " - " +
                                                campo.Llave
                                              }
                                              key={campo.CodigoCampo}
                                            >
                                              {campo.hijos.map((hijo) => (
                                                <option
                                                  key={
                                                    hijo.CodigoCampoCatalogoSeleccion
                                                  }
                                                  value={
                                                    hijo.CodigoCampoCatalogoSeleccion
                                                  }
                                                >
                                                  {hijo.Nombre +
                                                    " - " +
                                                    hijo.Llave}
                                                </option>
                                              ))}
                                            </optgroup>
                                          );
                                        } else {
                                          return (
                                            <option
                                              key={campo.CodigoCampo}
                                              value={campo.CodigoCampo}
                                            >
                                              {campo.Nombre +
                                                " - " +
                                                campo.Llave}
                                            </option>
                                          );
                                        }
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} lg={12} sm={12}>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#69bd4b",
                                    color: "white",
                                    width: "100%",
                                  }}
                                  className={classes.buttonAdd}
                                  onClick={() => this.editarCampo()}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </form>
                          </Grid>
                        </Grid>
                      </Paper>
                      <div style={{ marginBottom: "30px" }}></div>
                      {/* <Fab
                        onClick={() => this.editarCampo()}
                        color="primary"
                        aria-label="Ver detalle"
                        className={classes.fab}
                        size="small"
                        style={{ backgroundColor: "#69BD4B", 
                        color: "white",
                        position: 'absolute',
                        bottom: 5,
                        right: 250,
                      }}
                      >
                        <SaveIcon />
                      </Fab> */}

                      <List style={{ width: rightDrawerWidth }}>
                        <ListItem>
                          <ListItemText></ListItemText>
                        </ListItem>
                      </List>
                    </Drawer>
                  </div>
                </Grid>
              </Grid>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div id="bottom" ref={this.bottomRef}></div>
          </Paper>
        </Container>
        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />
        {/* {this.props.showAddButton !== false &&(
          <AddButton onClick={this.handleDrawerOpen} />
        )} */}

        <ModalAskNumber
          open={this.state.openAskNumberModal}
          handleClose={this.handleAskNumberModalClose}
          handleConfirm={this.handleSaveSeccion}
        />

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={this.state.openFormPanel}
          onClose={this.handleCloseFormPanel}
        >
          <div style={getModalStyle()} className={classes.bigPaper}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={12} sm={12}>
                    {this.state.codigoFormDeCampo === "" ||
                    this.state.codigoFormDeCampo === null ||
                    this.state.codigoFormDeCampo === undefined ? (
                      <div>No hay datos para mostrar</div>
                    ) : (
                      <FormDesignerMirror
                        codigoPlantilla={Number(this.state.codigoFormDeCampo)}
                        esGrid={true}
                      />
                      // <></>
                    )}
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const FormDesignerContainer = withStyles(styles)(FormDesigner);
export default withRouter(FormDesignerContainer);
