import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Visibility from "@material-ui/icons/Visibility";
import { DropzoneArea } from "material-ui-dropzone";
import Bitacora from "../components/bitacora";

import CircularProgress from "@material-ui/core/CircularProgress";
import Mensaje from "../../helpers/components/message";

import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import DetalleGestionGeneral from "../../ejecucion-avisos/components/gestion-tarea-detalle";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import axios from "axios";

import Container from "@material-ui/core/Container";

import { toast } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";

import ModalAgregarItem from "../../helpers/components/modal-agregar-item";
import UserInfo from "../../usuario-info/containers/user-info";
import ModalSeleccionarEtiquetas from "../containers/modal-seleccionar-etiquetas";
import EncabezadoAvance from "./encabezado-avance";
import TareaFormulario from "./tarea-formulario";
import UsuariosResumen from "./usuarios-resumen";
import UsuariosResumenEditable from "./usuarios-resumen-tarea";

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: 500,
  },
  //Estilos de Usuarios Asignados
  input: {
    marginTop: 15,
  },
  inputTitulo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColor: {
    color: "#2196F3",
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  //Estilos para la descripcion
  descripcion: {
    textAlign: "left",
    color: "black",
  },
  //estilo de boton
  buttonOk: {
    margin: theme.spacing(1),
    backgroundColor: "#69BD4B",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  buttonCancel: {
    margin: theme.spacing(1),
    backgroundColor: "#E74C3C",
  },
  buttonForm: {
    margin: theme.spacing(1),
    width: "95%",
    "&:hover": {
      backgroundColor: "#42A5F5",
    },
  },
  //Estilos para boton de subtareas
  subTareas: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function DetalleTarea(props) {
  const classes = useStyles();
  const [codigoTarea, setCodigoTarea] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tarea, setTarea] = useState(null);
  const [comentario, setComentario] = useState(null);
  const [permisoEditar, setPermisoEditar] = useState(false);
  const [tieneSubtareasPendientes, setTareasPendientes] = useState(false);
  const [permisoEstadoTarea, setEstadoTarea] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [fechaVencimiento, setFechaVencimiento] = useState(null);
  const [colorSemaforo, setColorSemaforo] = useState("");
  const [porcentajeAvance, setPorcentajeAvance] = useState(null);
  const [haySubTareas, sethaySubtareas] = useState(true);
  const [mensajes, setMensajes] = useState([]);

  //Detalle Gestion
  const [verFormGestion, setVerFormGestion] = useState(false);
  const [aviso, setAviso] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [listaPlantillaNotificaciones, setlistaPlantillaNotificaciones] =
    useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [detallePermisoUsuarioActual, setDetallePermisoUsuarioActual] =
    useState({
      CodigoAvisoUsuarioPermiso: null,
      Nombre: "No asignado",
      Create: false,
      Read: false,
      Update: false,
      Delete: false,
      Download: false,
      Print: false,
      Activo: false,
      Eliminado: false,
    });

  //Usuarios asignados
  const [openUsuariosAsignados, setOpenUsuariosAsignados] = useState(false);
  const [itemsUsuarios, setItemsUsuarios] = useState([]);

  //SubTareas
  const [subTareas, setSubTareas] = useState(null);
  const [subTareasPorcentajeAvance, setSubTareasPorcentajeAvance] = useState(0);

  //Etiquetas
  const [openEtiquetas, setOpenEtiquetas] = useState(false);

  //Campos personalizados const & setters
  const [camposPersonalizados, setCamposPersonalizados] = useState([]);
  const [location, setLocation] = useState({ lon: -1, lat: -1 });

  useEffect(() => {
    sethaySubtareas(props.haySubtareas);
  }, [props.haySubtareas]);
  useEffect(() => {
    makeRequestTarea();
  }, [props.codigoTarea]);

  const makeRequestTarea = () => {
    setSubTareas(null);

    if (props.codigoTarea != null) {
      setIsLoading(true);
    }
    setCodigoTarea(props.codigoTarea);

    const makeRequest = async () => {
      const request = await API.fetchGetRequest(
        `api/aviso/tarea/${props.codigoTarea}`
      );
      const llavePadre = "AITAvisoTarea";
      const requestChat = await API.fetchGetRequest(
        `api/chat/obtenerMensajes/${props.codigoTarea}/${llavePadre}`
      );
      setMensajes(requestChat.data.data);

      const session = await isSignedIn();

      if (request.data.response) {
        const tareaBuff = request.data.data;
        console.log("serreciete");
        console.log(tareaBuff);

        setTarea(tareaBuff);
        if (tareaBuff.AvisoTarea.TareasPendientes === 0) {
          setTareasPendientes(false);
        } else {
          setTareasPendientes(true);
        }
        if (tareaBuff.AvisoTarea.CodigoEstadoTarea > 1) {
          setEstadoTarea(true);
        } else {
          setEstadoTarea(false);
        }
        console.log("tarea buff", tareaBuff.AvisoTarea);
        setFechaVencimiento(tareaBuff.AvisoTarea.FechaVencimiento);
        setColorSemaforo(tareaBuff.AvisoTarea.colorSemaforo);
        setPorcentajeAvance(tareaBuff.AvisoTarea.PorcentajeAvance);
        setComentario(request.data.data.AvisoTarea.Comentario);
        setCamposPersonalizados(request.data.data.CamposFormulario);

        //Permisos para editar tarea..
        if (
          tareaBuff.UsuariosAsignados.filter(
            (f) => f.CodigoUsuario == session.codigoUsuario
          ).length > 0
        ) {
          //Si el usuario logeado esta en los usuarios asignados, le damos permiso para editar.. de lo contrario nanain....
          setPermisoEditar(true);
        } else if (
          props.detallePermisoUsuarioActual !== null &&
          props.detallePermisoUsuarioActual !== undefined &&
          props.detallePermisoUsuarioActual.God !== null &&
          props.detallePermisoUsuarioActual.God !== undefined
        ) {
          //@rda: hay que agregar una condicional para que si el usuario tiene God=true por tener permiso G (modo dios) permiso editar sea true
          if (props.detallePermisoUsuarioActual.God === true) {
            setPermisoEditar(true);
          }
        } else {
          setPermisoEditar(false);
        }
      }

      setIsLoading(false);
    };

    if (props.codigoTarea != null) {
      makeRequest();
      getSubTareas();
    } else {
      setTarea(null);
      setSubTareas(null);
    }

    setDisableButtons(false);
  };

  const getSubTareas = async () => {
    const request = await API.fetchGetRequest(
      `api/aviso/tareas/0?codigoAvisoTarea=${props.codigoTarea}`
    );

    if (request.data.response) {
      setSubTareas(request.data.data);
      if (request.data.data.length > 0) {
        const totalTareas = request.data.data.length;
        const tareasTerminadas = request.data.data.filter(
          (f) => f.CodigoEstadoTarea === 3 || f.CodigoEstadoTarea === 4
        ).length;
        setSubTareasPorcentajeAvance((tareasTerminadas / totalTareas) * 100);
      } else {
        setSubTareasPorcentajeAvance(100);
      }
    }
  };

  /*******************************************************************
   *********** FUNCIONES DE ACEPTAR O CANCELAR TAREA ******************
   ********************************************************************
   */
  // const onClickOk = async () => {
  //   setDisableButtons(true);
  //   /* const onClick = async () =>{

  //   }
  //    */
  //   const session = await isSignedIn();
  //   /* const { propsButtonOk, propsButtonCancel } = this.state; */

  //   let objectRequest = {};
  //   objectRequest.CodigoUsuario = session.codigoUsuario;
  //   objectRequest.CodigoEstadoTarea = 3;
  //   objectRequest.Comentario = comentario;

  //   if (
  //     // objectRequest.Comentario != null &&
  //     // objectRequest.Comentario.trim() != ""
  //     true
  //   ) {
  //     await postAvisoTarea(objectRequest);

  //     setPermisoEditar(false);
  //     setDisableButtons(true);
  //   } else {
  //     toast.warn("Ingresa un comentario para continuar.");
  //     setDisableButtons(false);
  //   }
  // };

  // const [errorMessage, setErrorMessage] = useState("");

  // const onClickOk = async () => {
  //   setDisableButtons(true);
  //   setErrorMessage(""); // Limpia el mensaje de error previo

  //   const session = await isSignedIn();
  //   let objectRequest = {};
  //   objectRequest.CodigoUsuario = session.codigoUsuario;
  //   objectRequest.CodigoEstadoTarea = 3;
  //   objectRequest.Comentario = comentario;

  //   try {
  //     const response = await postAvisoTarea(objectRequest);

  //     if (response.data.response) {
  //       setPermisoEditar(false);
  //       setDisableButtons(true);
  //       toast.success("Gestión completada exitosamente");
  //     } else {
  //       throw new Error("Server rejected request");
  //     }
  //   } catch (error) {
  //     setErrorMessage(
  //       "Faltan campos obligatorios por llenar. Completa la información."
  //     );
  //     setDisableButtons(false);
  //   }
  // };

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onClickOk = async () => {
    setDisableButtons(true);
    setErrorMessage(""); // Limpia el mensaje de error previo

    const session = await isSignedIn();

    // Validar los campos obligatorios antes de enviar
    const camposRequeridos = camposPersonalizados.filter(
      (campo) => campo.Requerido
    );

    // let camposIncompletos = camposRequeridos.filter(
    //   (campo) =>
    //     !campo.Valor ||
    //     campo.Valor.Valor === "" ||
    //     campo.Valor.Valor.length === 0
    // );

    let camposIncompletos = camposRequeridos.filter(
      (campo) =>
        !campo.Valor ||
        //!campo.Valor.Valor || // Verifica que 'campo.Valor.Valor' no sea null o undefined
        campo.Valor.Valor === "" ||
        (campo.CodigoTipoCampo == 8 &&
          Array.isArray(campo.Valor) &&
          campo.Valor.length === 0) // Verifica si es un array antes de acceder a 'length'
    );

    console.log("camposIncompletos", camposIncompletos);

    if (camposIncompletos.length > 0) {
      setErrorMessage(
        "Faltan campos obligatorios por llenar. Completa la información."
      );
      setDisableButtons(false);
      return;
    }

    let objectRequest = {
      CodigoUsuario: session.codigoUsuario,
      CodigoEstadoTarea: 3,
      Comentario: comentario,
    };

    try {
      const response = await postAvisoTarea(objectRequest);

      if (response) {
        // Si la respuesta es exitosa, mostramos mensaje de éxito
        setSuccessMessage("Guardado con éxito");
        setDisableButtons(true);
        toast.success("Gestión completada exitosamente");
      }
    } catch (error) {
      console.error("Error capturado:", error);
      setErrorMessage(
        "Error al procesar la solicitud. Por favor, intenta de nuevo."
      );
      setDisableButtons(false);
    }
  };

  const onClickCancel = async () => {
    setDisableButtons(true);
    /* const onClick = async () =>{

    }
     */
    const session = await isSignedIn();
    /* const { propsButtonOk, propsButtonCancel } = this.state; */

    let objectRequest = {};
    objectRequest.CodigoUsuario = session.codigoUsuario;
    objectRequest.CodigoEstadoTarea = 4;
    objectRequest.Comentario = comentario;

    if (
      // objectRequest.Comentario != null &&
      // objectRequest.Comentario.trim() != ""
      true
    ) {
      await postAvisoTarea(objectRequest);

      setPermisoEditar(false);
      setDisableButtons(true);
    } else {
      toast.warn("Ingresa un comentario para continuar.");
      setDisableButtons(false);
    }
  };

  /*******************************************************************
   *********** FUNCIONES DE USUARIOS ASIGNADOS ************************
   ********************************************************************
   */

  function clickEliminarUsuarioOld(usuario) {
    if (!permisoEditar) {
      toast.warn("No tienes permisos para editar esta tarea!");
      return;
    }
    const makeRequest = async () => {
      const usuarios = tarea.UsuariosAsignados.filter(
        (f) => f.CodigoUsuario != usuario.CodigoUsuario
      );

      const nuevosUsuarios = usuarios.map((m) => m.CodigoUsuario);

      const session = await isSignedIn();

      const objects = {
        CodigosUsuarios: nuevosUsuarios,
        CodigoUsuario: session.codigoUsuario,
      };

      await postAvisoTarea(objects);
    };
    //Asociamos inmediatamente los nuevos usaurios.
    const usuarios = tarea.UsuariosAsignados.filter(
      (f) => f.CodigoUsuario != usuario.CodigoUsuario
    );
    var tareaBuff = { ...tarea };
    tareaBuff.UsuariosAsignados = usuarios;

    console.log("las tarea buff", tareaBuff);

    setTarea(tareaBuff);
    makeRequest();
  }

  function clickEliminarUsuario(usuario) {
    if (!permisoEditar) {
      toast.warn("No tienes permisos para editar esta tarea!");
      return;
    }
    const makeRequest = async () => {
      const usuarios = tarea.UsuariosAsignados.filter(
        (f) => f.CodigoUsuario != usuario.CodigoUsuario
      );

      const nuevosUsuarios = usuarios.map((m) => m.CodigoUsuario);

      const session = await isSignedIn();

      const data = {
        codigoAvisoTareaUsuario: usuario.CodigoAvisoTareaUsuario,
        usuarioOpera: session.userName,
      };

      const uri = `api/aviso/tarea/removerUsuarioTarea`;

      const request = await API.post(uri, data);
      console.log("Esta es la data", request.data);
    };
    //Asociamos inmediatamente los nuevos usaurios.
    const usuarios = tarea.UsuariosAsignados.filter(
      (f) => f.CodigoUsuario != usuario.CodigoUsuario
    );
    var tareaBuff = { ...tarea };
    tareaBuff.UsuariosAsignados = usuarios;

    console.log("las tarea buff", tareaBuff);

    setTarea(tareaBuff);
    makeRequest();
  }

  const onClickBuscarUsuario = (text) => {
    //Buscar

    const makeRequest = async () => {
      const request = await API.fetchGetRequest(`api/usuario/buscar/${text}`);

      if (request.data.response) {
        //Transformamos los items
        const items = request.data.usuarios.map((usuario) => ({
          name: usuario.NombreCompletoUsuario,
          CodigoUsuario: usuario.CodigoUsuario,
        }));

        setItemsUsuarios(items);
      }
    };

    makeRequest();
  };

  const onClickSeleccionUsuarioOld = (item) => {
    //Debemos armar el array [codigos_usuarios];

    const makeRequest = async () => {
      const session = await isSignedIn();

      //Verificamos si ya existe en los usuarios asignados existentes.

      const existeUsuario = tarea.UsuariosAsignados.filter(
        (f) => f.CodigoUsuario == item.CodigoUsuario
      );

      console.log("existeUsaurio", existeUsuario);
      if (existeUsuario.length >= 1) {
        toast.warn("El usuario ya esta asociado a la tarea");
        return;
      }

      const codigosUsuariosAsignados = tarea.UsuariosAsignados.map((m) => {
        return m.CodigoUsuario;
      });

      const nuevosUsuariosAsignados = [
        ...codigosUsuariosAsignados,
        item.CodigoUsuario,
      ];

      const objects = {
        CodigosUsuarios: nuevosUsuariosAsignados,
        CodigoUsuario: session.codigoUsuario,
      };

      await postAvisoTarea(objects);

      setOpenUsuariosAsignados(false);
    };
    makeRequest();
  };

  const onClickSeleccionUsuario = (item) => {
    //Debemos armar el array [codigos_usuarios];

    const makeRequest = async () => {
      const session = await isSignedIn();

      //Verificamos si ya existe en los usuarios asignados existentes.

      const existeUsuario = tarea.UsuariosAsignados.filter(
        (f) => f.CodigoUsuario == item.CodigoUsuario
      );

      console.log("existeUsaurio", existeUsuario);
      if (existeUsuario.length >= 1) {
        toast.warn("El usuario ya esta asociado a la tarea");
        return;
      }

      const codigosUsuariosAsignados = tarea.UsuariosAsignados.map((m) => {
        return m.CodigoUsuario;
      });

      setOpenUsuariosAsignados(false);

      const data = {
        codigoTarea: codigoTarea,
        codigoUsuario: item.CodigoUsuario,
        usuarioOpera: session.userName,
      };

      const uri = `api/aviso/tarea/agregarUsuarioTarea`;

      const request = await API.post(uri, data);
      console.log("Esta es la data", request.data);

      const nuevoUsuario = request.data.data;
      console.log("usuario", request.data.data);
      var tareaBuff = { ...tarea };
      tareaBuff.UsuariosAsignados.push(nuevoUsuario);
      setTarea(tareaBuff);
    };
    makeRequest();
  };

  /********************************************************************
   *********** FUNCIONES DE ARCHIVOS ADJUNTOS *************************
   ********************************************************************
   */
  function newTab(url) {
    var form = document.createElement("form");
    form.method = "GET";
    form.action = url;
    form.target = "_blank";
    document.body.appendChild(form);
    form.submit();
  }

  async function newTabAuth({ fileName, key }) {
    const session = await isSignedIn();
    const baseApi = await API.baseApi();

    axios({
      url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  const uploadFiles = (files) => {
    const makeRequest = async () => {
      console.log("Uploading...", files);
      const session = await isSignedIn();

      if (files.length > 0) {
        let data = new FormData();
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoUsuarioInserto", session["codigoUsuario"]);

        await files.forEach(async (element2, j) => {
          await data.append("files[]", element2);
        });

        let request = await API.post(
          `api/aviso/tarea/agregarMultimedia/${codigoTarea}`,
          data,
          { "content-type": "multipart/form-data" }
        );

        let tareaBuff = { ...tarea };
        tareaBuff.Multimedia = [...tareaBuff.Multimedia, ...request.data.data];

        await setTarea(tareaBuff);
      }
    };

    makeRequest();
  };

  const eliminarArchivo = (file) => {
    if (!permisoEditar) {
      toast.warn("No puedes eliminar archivos");
      return;
    }

    const makeRequest = async () => {
      const session = await isSignedIn();

      let request = await API.fetchGetRequestWithParams(
        `api/aviso/tarea/inactivarMultimedia/${file.id}`,
        { codigoUsuario: session.codigoUsuario }
      );
    };

    var tareaActualizada = { ...tarea };

    tareaActualizada.Multimedia = tarea.Multimedia.filter((f) => f != file);

    setTarea(tareaActualizada);

    makeRequest();
  };

  /********************************************************************
   *********** FUNCIONES ETIQUETAS ************************************
   ********************************************************************
   */
  function clickEliminarEtiqueta(row) {
    if (!permisoEditar) {
      toast.warn("No tienes permisos para editar esta tarea!");
      return;
    }
    const makeRequest = async () => {
      const etiquetas = tarea.EtiquetasAsignadas.filter(
        (f) => f.CodigoCausa != row.CodigoCausa
      );

      const nuevasCausas = etiquetas.map((m) => m.CodigoCausa);

      const session = await isSignedIn();

      const objects = {
        CodigosCausas: nuevasCausas,
        CodigoUsuario: session.codigoUsuario,
      };

      await postAvisoTarea(objects);
    };
    //Asociamos inmediatamente los nuevos usaurios.
    const etiquetas = tarea.EtiquetasAsignadas.filter(
      (f) => f.CodigoCausa != row.CodigoCausa
    );
    var tareaBuff = { ...tarea };
    tareaBuff.EtiquetasAsignadas = etiquetas;

    setTarea(tareaBuff);
    makeRequest();
  }

  const onClickSeleccionEtiqueta = (item) => {
    const makeRequest = async () => {
      const session = await isSignedIn();

      //Verificamos si ya existe en los usuarios asignados existentes.

      const existeUsuario = tarea.EtiquetasAsignadas.filter(
        (f) => f.CodigoCausa == item
      );

      if (existeUsuario.length >= 1) {
        toast.warn("La etiqueta ya existe en la tarea");
        return;
      }

      const codigosCausasAsig = tarea.EtiquetasAsignadas.map((m) => {
        return m.CodigoCausa;
      });

      const nuevasEtiquetas = [...codigosCausasAsig, item];

      const objects = {
        CodigosCausas: nuevasEtiquetas,
        CodigoUsuario: session.codigoUsuario,
      };

      await postAvisoTarea(objects);
      setOpenEtiquetas(false);
    };
    makeRequest();
  };

  /********************************************************************
   *********** FUNCIONES GENERALES ************************************
   ********************************************************************
   */
  const postAvisoTarea = async (objectRequest) => {
    try {
      const uri = `api/aviso/tarea/${codigoTarea}`;
      let objects = objectRequest;

      /* const { location } = this.state; */
      /*       objects = { ...objects, Longitud: location.lon, Latitud: location.lat }; */
      //objects = { ...objects };

      const request = await API.post(uri, objects);
      console.log("Esta es la data", request.data);
      if (request.data.response) {
        //OK
        //Alert.alert(request.data.mensaje);
        toast.info(request.data.mensaje);

        if (
          request.data.data != null &&
          typeof request.data.data.UsuariosAsignados !== "undefined"
        ) {
          var tareaBuff = { ...tarea };

          tareaBuff.UsuariosAsignados = request.data.data.UsuariosAsignados;

          setTarea(tareaBuff);
        }

        try {
          if (typeof request.data.data.EtiquetasAsignadas !== "undefined") {
            var tareaBuff = { ...tarea };

            tareaBuff.EtiquetasAsignadas = request.data.data.EtiquetasAsignadas;

            setTarea(tareaBuff);
            //setColorSemaforo()
          }
        } catch (e) {}

        //Refrescamos las tareas
        props.refrescarTareas();
      } else {
        //El API alerta error.
        toast.error(request.data.mensaje);
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("El error", e);
    }
  };

  //Vamos a las subtareas de la tarea.
  const clickSubTareas = () => {
    props.onClickSubTareas(tarea);
  };

  /********************************************************************
   *********** FUNCIONES CAMPOS PERSONALIZADOS************************
   ********************************************************************
   */
  const postAviso = async (objectRequest) => {
    await almacenarGeoposicion();

    try {
      const uri = `api/formulario/editar/`;
      let objects = objectRequest;

      objects = { ...objects, Longitud: location.lon, Latitud: location.lat };

      const request = await API.post(uri, objects);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        //Alert.alert(request.data.mensaje);
        //Verificamos si vienen campos con nuevos valores (Ya que pueden actualizarse dependiendo de la actualización de un campo)
        //Ej. Si un campo calculado depende de uno que se esta modificando, este hay que actualizarlo.
        if (request.data.data.camposModificadosNuevos.length > 0) {
          request.data.data.camposModificadosNuevos.map((campoNuevo) => {
            let camposPersonalizadosNuevos = [...camposPersonalizados];
            let indexCampoViejoAReemplazar =
              camposPersonalizadosNuevos.findIndex(
                (f) => campoNuevo.CodigoCampo === f.CodigoCampo
              );

            camposPersonalizadosNuevos[indexCampoViejoAReemplazar].Valor =
              campoNuevo;

            setCamposPersonalizados(camposPersonalizadosNuevos);
          });
        }
      } else {
        //El API alerta error.
        window.alert(request.data.mensaje);
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("postAviso", e);
    }
  };

  const almacenarGeoposicion = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const geolocation = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          setLocation(geolocation);
        });
      } else {
        window.alert("Su navegador no soporta geolocalización");
      }
    } catch (e) {
      setLocation({ lat: -1, long: -1 });
    }
    //await this.setState({ location });
  };

  const onChangeCampoPersonalizado = (codigoCampo) => (event) => {
    //const numeroEstadosPosteriores = aviso.NumeroEstadosPosteriores;

    // console.log("NumeroEstadoPosteriores", numeroEstadosPosteriores);
    // console.log("PermisoEditar", permisoEditar);
    if (!permisoEditar) {
      toast.warn("No tienes permiso para editar este campo.");
      return;
    }

    let valor;
    let tipoCampo;

    let camposPer = [...camposPersonalizados];

    const findIndex = camposPer.findIndex((f) => f.CodigoCampo == codigoCampo);

    tipoCampo = camposPer[findIndex].CodigoTipoCampo;

    if (tipoCampo == 7) {
      valor = event.target.checked;
    } else if (tipoCampo == 5 || tipoCampo === 22 || tipoCampo == 23) {
      var arr = [];
      arr.push(event.target.value);
      valor = arr;
    } else {
      valor = event.target.value;
    }

    camposPer[findIndex].Valor.Valor = valor;

    setCamposPersonalizados(camposPer);

    guardarEditarCampoPersonalizado(camposPer[findIndex].CodigoCampo);
  };

  const onChangeCampoPersonalizadosAutocomplete =
    (codigoCampo) => (event, newValue) => {
      //const numeroEstadosPosteriores = aviso.NumeroEstadosPosteriores;

      // console.log("NumeroEstadoPosteriores", numeroEstadosPosteriores);
      // console.log("PermisoEditar", permisoEditar);
      if (!permisoEditar) {
        toast.warn("No tienes permiso para editar este campo.");
        return;
      }

      let valor;
      let tipoCampo;

      let camposPer = [...camposPersonalizados];

      const findIndex = camposPer.findIndex(
        (f) => f.CodigoCampo == codigoCampo
      );

      tipoCampo = camposPer[findIndex].CodigoTipoCampo;

      if (tipoCampo == 7) {
        valor = event.target.checked;
      } else if (tipoCampo === 22) {
        valor = newValue.id;
      } else {
        valor = event.target.value;
      }

      camposPer[findIndex].Valor.Valor = valor;

      setCamposPersonalizados(camposPer);

      guardarEditarCampoPersonalizado(camposPer[findIndex].CodigoCampo);
    };

  const guardarEditarCampoPersonalizado = async (codigoCampo) => {
    let objectRequest = {};
    const session = await isSignedIn();

    let campo = camposPersonalizados.find((f) => f.CodigoCampo == codigoCampo);
    let findIndex = camposPersonalizados.findIndex(
      (f) => f.CodigoCampo == codigoCampo
    );

    objectRequest = {
      // CampoPersonalizado: true,
      // CodigoTipoCampo: campo.CodigoTipoCampo,
      // CodigoAvisoCampoValor: campo.Valor.CodigoAvisoCampoValor,
      // CampoPersonalizadoValor: campo.Valor.Valor,
      // CodigoUsuario: session.codigoUsuario,
      // LlaveCampo: campo.LlaveCampo,

      CodigoUsuario: session.codigoUsuario,
      CodigoEstado: null,
      Activo: true,
      CampoPersonalizadoActivo: null,
      CodigoFormularioCampoValor: campo.Valor.CodigoFormularioCampoValor,
      CampoPersonalizadoValor: campo.Valor.Valor,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      LlaveCampo: campo.LlaveCampo,
      CodigoPadre: codigoTarea,
      TablaPadre: "AITAvisoTarea",
    };

    await postAviso(objectRequest);
  };

  const onPressInactivarCampoValor = async (campo) => {
    const session = await isSignedIn();
    const objectRequest = {
      CodigoUsuario: session.codigoUsuario,
      ...campo,
    };
    const response = await postAviso(objectRequest);
    return response;
  };

  const convertirFecha = async (fecha) => {
    var dateTime = new Date(fecha);
    console.log("Nueva fehca", dateTime);
    return fecha.toString();
  };

  const onClickFormularioGestion = async () => {
    const res = !verFormGestion;
    setVerFormGestion(res);
  };
  //FIN FUNCIONES CAMPOS PERSONALIZADOS
  return (
    <div>
      <div class={classes.tituloPaper}>
        <div class={classes.container}>
          {tarea != null && (
            <Typography variant="h5" gutterBottom>
              {tarea.AvisoTarea.Titulo}
            </Typography>
          )}
        </div>
        <div>
          {/* {subTareas !== null && subTareas.length > 0 && ( */}
          {subTareas !== null &&
            tarea != null &&
            !isLoading &&
            haySubTareas && (
              <div className={classes.subTareas}>
                {/*<Button
                onClick={clickSubTareas}
                className={classes.iconColor}
                endIcon={<AssignmentIcon />}
              >
                {subTareas.length +
                  " Sub Tarea" +
                  (subTareas.length > 1 ? "s" : "")}
              </Button>*/}
                <EncabezadoAvance percent={porcentajeAvance} />
              </div>
            )}
          {!haySubTareas && (
            <Button
              onClick={() => {
                window.location.href =
                  "/gestion/" + tarea.AvisoTarea.CodigoAviso;
              }}
              color="primary"
            >
              IR A LA GESTION
            </Button>
          )}

          {/* {subTareas !== null && subTareas.length === 0 && (
            <Typography variant="h7" gutterBottom>
              No hay sub tareas
            </Typography>
          )} */}
        </div>
      </div>
      <Paper className={classes.paper}>
        {isLoading && (
          <div>
            <CircularProgress />
          </div>
        )}

        {codigoTarea == null && (
          <div>
            <Mensaje>Selecciona una tarea.</Mensaje>
          </div>
        )}
        <Container maxWidth="md">
          {tarea != null && !isLoading && (
            <>
              <div className={classes.descripcion}>
                <Typography variant="subtitle1" gutterBottom>
                  {tarea.AvisoTarea.Descripcion}
                </Typography>
                <br />
              </div>
              <>
                {tarea.AvisoTarea.MostrarEncabezadoGestion === true && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.buttonForm}
                      startIcon={<Visibility />}
                      onClick={onClickFormularioGestion}
                    >
                      {verFormGestion ? "Ocultar " : "Ver "} Encabezado de
                      Gestión
                    </Button>
                    <>
                      {verFormGestion && (
                        <DetalleGestionGeneral
                          codigoTarea={props.codigoTarea}
                        />
                      )}
                    </>
                  </>
                )}
              </>

              {tarea.AvisoTarea.MostrarUsuariosAsignados === true && (
                <div className={classes.input}>
                  <div className={classes.inputTitulo}>
                    <Typography variant="subtitle1" gutterBottom>
                      Usuarios Asignados
                    </Typography>

                    {permisoEditar && (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => setOpenUsuariosAsignados(true)}
                      >
                        <PersonAddIcon
                          fontSize="small"
                          className={classes.iconColor}
                        />
                      </IconButton>
                    )}
                  </div>

                  <div className={classes.classChips}>
                    <UsuariosResumenEditable
                      usuarios={tarea.UsuariosAsignados}
                      removerUsuario={clickEliminarUsuario}
                    />
                    {/* {tarea.UsuariosAsignados.map((row, i) => (
                      <UserInfo userName={row.Usuario} imagenPerfil={null}>
                        <Chip
                          size="small"
                          avatar={
                            <Avatar>
                              {row.NombreCompletoUsuario.length > 1
                                ? row.NombreCompletoUsuario.substring(
                                    0,
                                    1
                                  ).toUpperCase()
                                : ""}
                            </Avatar>
                          }
                          label={row.NombreCompletoUsuario}
                          onDelete={() => clickEliminarUsuario(row)}
                        />
                      </UserInfo>
                    ))} */}
                  </div>
                </div>
              )}

              {tarea.AvisoTarea.MostrarEtiquetas === true && (
                <div className={classes.input}>
                  <div className={classes.inputTitulo}>
                    <Typography variant="subtitle1" gutterBottom>
                      Etiquetas
                    </Typography>

                    {permisoEditar && (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => setOpenEtiquetas(true)}
                      >
                        <LocalOfferIcon
                          fontSize="small"
                          className={classes.iconColor}
                        />
                      </IconButton>
                    )}
                  </div>

                  <div className={classes.classChips}>
                    {tarea.EtiquetasAsignadas.map((row, i) => (
                      <Chip
                        size="small"
                        label={row.Nombre}
                        onDelete={() => clickEliminarEtiqueta(row)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Comenté la fecha de vencimiento porque en móvil no existe y al parecer no se utiliza y en este sprint se busca limpiar las tareas */}
              {/* <div className={classes.input}>
                <div className={classes.inputTitulo}>
                  <Typography variant="subtitle1" gutterBottom>
                    Fecha Vencimiento
                  </Typography>
                  <IconButton aria-label="calendar" className={classes.margin}>
                    <CalendarTodayIcon
                      fontSize="small"
                      className={classes.iconColor}
                    />
                  </IconButton>
                </div>
                <div className={classes.classChips}>
                  {permisoEditar && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={fechaVencimiento}
                        onChange={(date) => {
                          setFechaVencimiento(date);
                          postAvisoTarea({
                            FechaVencimiento: date,
                            CambioFecha: true,
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        placeholder={"Fecha de vencimiento"}
                        className={classes.input}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </div>
              </div> */}

              {tarea.AvisoTarea.MostrarAdjuntos === true && (
                <div className={classes.input}>
                  <div className={classes.inputTitulo}>
                    <Typography variant="subtitle1" gutterBottom>
                      Archivos Adjuntos
                    </Typography>

                    {permisoEditar && (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                      >
                        <AttachFileIcon
                          fontSize="small"
                          className={classes.iconColor}
                        />
                      </IconButton>
                    )}
                  </div>

                  <div className={classes.classChips}>
                    {tarea.Multimedia.map((row, i) => (
                      <Chip
                        size="small"
                        icon={<AttachFileIcon />}
                        label={row.name}
                        onClick={() => {
                          // newTab(row.path);
                          newTabAuth({
                            fileName: row.name,
                            key: "IMAGE_REPOSITORY_AVISOS_TAREA",
                          });
                        }}
                        onDelete={() => eliminarArchivo(row)}
                      />
                    ))}
                    {tarea.Multimedia.length == 0 && (
                      <Mensaje>No hay archivos adjuntos.</Mensaje>
                    )}
                    {permisoEditar && (
                      <DropzoneArea
                        dropzoneText={"Adjuntar archivos"}
                        onChange={uploadFiles.bind(this)}
                        maxFileSize={1073741824}
                        filesLimit={1}
                        files={null}
                      />
                    )}
                  </div>
                </div>
              )}

              {/*<TextField
                className={classes.input}
                required
                label="Comentario"
                defaultValue={comentario}
                variant="outlined"
                multiline
                fullWidth
                rows={3}
                onChange={(event) => {
                  setComentario(event.target.value.trim());
                }}
                //readOnly={!permisoEditar}
                disabled={!permisoEditar}
              />*/}

              {tarea.AvisoTarea.MostrarComentarios === true && (
                <div style={{ marginTop: 10 }}>
                  <Bitacora
                    permisoEditar={permisoEditar}
                    mensajes={mensajes}
                    codigoTarea={props.codigoTarea}
                  />
                </div>
              )}
              {Array.isArray(camposPersonalizados) &&
                camposPersonalizados.length > 0 && (
                  <TareaFormulario
                    tarea={tarea}
                    camposPersonalizados={camposPersonalizados}
                    recargar={makeRequestTarea}
                    onPressInactivarCampoValor={onPressInactivarCampoValor}
                    quitarDocAdjuntoDeEstado={() => {}}
                    permisoEditar={permisoEditar}
                    onChangeCampoPersonalizado={onChangeCampoPersonalizado}
                    onChangeCampoPersonalizadosAutocomplete={
                      onChangeCampoPersonalizadosAutocomplete
                    }
                    CodigoPadre={codigoTarea}
                    TablaPadre={"AITAvisoTarea"}
                  />
                )}

              {permisoEditar &&
                !tieneSubtareasPendientes &&
                permisoEstadoTarea && (
                  <div>
                    {/* Aquí muestra el mensaje de error si existe */}
                    {errorMessage && (
                      <Typography color="error" variant="body1">
                        {errorMessage}
                      </Typography>
                    )}
                    {tarea.AvisoTarea.MostrarBotonCancelar === true && (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonCancel}
                        startIcon={<CloseIcon />}
                        disabled={disableButtons}
                        onClick={() => onClickCancel()}
                      >
                        CANCELAR / ANULAR
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonOk}
                      endIcon={<CheckIcon>send</CheckIcon>}
                      onClick={() => onClickOk()}
                      disabled={disableButtons}
                    >
                      ACEPTAR
                    </Button>
                  </div>
                )}

              {tieneSubtareasPendientes && (
                <h3>
                  Esta tarea tiene {tarea.AvisoTarea.TareasPendientes} subtareas
                  pendientes de marcar como completadas
                </h3>
              )}
              {!permisoEstadoTarea && (
                <h3>Esta tarea está en estado pendiente</h3>
              )}
            </>
          )}
        </Container>
      </Paper>
      <ModalAgregarItem
        title={"Agregar Usuario"}
        inputPlaceholder={"Busca a un usuario..."}
        open={openUsuariosAsignados}
        items={itemsUsuarios}
        setOpen={(open) => {
          setOpenUsuariosAsignados(open);
          setItemsUsuarios([]);
        }}
        onClickSearch={(text) => onClickBuscarUsuario(text)}
        onClickItem={(item) => onClickSeleccionUsuario(item)}
      />
      <ModalSeleccionarEtiquetas
        open={openEtiquetas}
        setOpen={(open) => setOpenEtiquetas(open)}
        onClickSeleccionEtiqueta={(row) => {
          onClickSeleccionEtiqueta(row);
        }}
      />

      {/*
      {tarea != null && !isLoading && (
        <Chat
          codigoPadre={codigoTarea}
          nombrePadre={tarea.AvisoTarea.Titulo}
          llavePadre="AITAvisoTarea"
        />
      )} */}
    </div>
  );
}
